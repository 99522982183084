import React, { useState, useEffect, useRef } from "react";
import "../assets/css/bp-Style.css";
import Bgimg from "../assets/img/service.jpg";
import Card from "react-bootstrap/Card";
import { useStore } from "../Hooks/Store";
import { useTranslation } from "react-i18next";
import ARFConfigData from "../config/bp-arf-form-config.json";

const Service = (props) => {
  const [selectedServiceType, setSelectedServiceType] = useState("");
  const [subCategory, setSubCategory] = useState();
  const [visible, setVisible] = useState(false);
  const { setCurrentService, setCurrentSubCategory } = useStore();
  const [subServices, setSubServices] = useState([]);
  const selectAppointment = useStore((state) => state.selectAppointment);
  const { t } = useTranslation("common");
  const inputElement = useRef(null);

  useEffect(() => {
    if (inputElement.current) {
      inputElement.current.focus();
    }
  }, []);

  useEffect(() => {
    if (visible) {
      document.body.style.overflow = "hidden";
      document.body.style.paddingRight = "15px";
    }
    return () => {
      document.body.style.overflow = "unset";
      document.body.style.paddingRight = "0px";
    };
  }, [visible]);

  const update = (e) => {
    setSelectedServiceType(e.target.value);
    const selectedServicesObj = ARFConfigData.configServices.find(obj => obj.serviceType === e.target.value)?.services ?? [];
    const selectedServices = selectedServicesObj.map((el) => <option key={t(el)}>{t(el)}</option>) ?? [];
    setSubServices(selectedServices);
    setSubCategory(selectedServicesObj[0] ?? '');
    setVisible(true);
  };

  const handleChange = (e) => {
    console.log('selectedSubCategory', e.target.value)
    setSubCategory(e.target.value);
  };
  const handleBack = (e) => {
    props.resetHome();
  };

  const handleNext = () => {
    if (subCategory === t("services.familytherapy")) {
      props.goToStep(3);
    } else if (selectedServiceType) {
      props.goToStep(2);
    } else {
      props.goToStep(1);
    }

    console.log('selectedServiceType', selectedServiceType, 'subCategory', t(subCategory))
    // Setting data to Zustand
    setCurrentService(t(selectedServiceType));
    setCurrentSubCategory(t(subCategory));
  };


  return (
    <div>
      <div className="row bp-card-form">
        <Card className="bp-card  col-lg-12  ">
          <Card.Img
            variant="top"
            src={Bgimg}
            id="hideimage"
            className="bp-image bp-service-img-position"
          />
          <Card.Body id="bp-form-center" className="bp-service-service">
            <div className="bp-service-scroll">
              <div className="container bp-service-wrapper">
                <div className="bp-service-algnmnt" id="bp-srvc-algnmnt">
                  <Card.Title className="bp-title">
                    {t("services.whichservice")}
                  </Card.Title>

                  <div className="bp-form">
                    <div className="bp-selectfill-item">
                      <select
                        id="bp-select-form"
                        className="bp-custom-select bp-select__field"
                        onChange={update}
                        ref={inputElement}
                      >
                        <option disabled selected>
                          {t("services.pleaseselect")}
                        </option>
                        {
                          ARFConfigData.configServices.map(obj => <option value={obj.serviceType}>{t(obj.serviceType)}</option>)
                        }
                      </select>
                    </div>

                    {visible && (
                      <div id="bp-select-center" className="bp-select-option2">
                        <select
                          id="bp-select-form"
                          className="bp-custom-select mt-2"
                          onChange={handleChange}
                          defaultValue={subCategory}
                        >
                          {subServices}
                        </select>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
      <div id="bp-serviceid" className="bp-submit-service">
        {!selectAppointment.length > 0 ? (
          <button
            id="bp-back-btnid"
            className="bp-back-btn"
            variant="outline"
            onClick={handleBack}
          >
            {t("services.back")}
          </button>
        ) : (
          <div className="bp-back-btn-remove"></div>
        )}

        <div id="bp-stepid" className="bp-step">
          <span className="bp-steps">
            <div className="bp-progress-bar">
              <div className="bp-current-step">{props.currentStep}</div> of
              <div className="bp-total-step">{props.totalSteps}</div>
            </div>
          </span>
        </div>
        <button
          id="bp-nxt-btn"
          style={
            selectedServiceType ? { color: "#ffffff" } : { color: "rgb(179 173 173)" }
          }
          className="bp-submit-btn2"
          onClick={handleNext}
        >
          {t("services.next")}
        </button>
      </div>
    </div>
  );
}

export default Service;