import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import "../assets/css/bp-Style.css";
import Button from "react-bootstrap/Button";
import Bgimg from "../assets/img/media.jpg";
import ARFConfig from "../config/bp-arf-form-config.json";
import { useStore } from "../Hooks/Store";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import { sanitizeTextInput } from "../utilties/utilities";
function Media(props) {
  const [participant, setParticipant] = useState([
    {
      firstName: "",
      lastName: "",
      phone: "",
      emailAddress: "",
      textArea: "",
    },
  ]);
  const [validated, setValidated] = useState(false);
  const { t } = useTranslation("common");
  const ARFEnvironment = useStore((state) => state.ARFEnvironment);
  const selectedLng = useStore((state) => state.selectedLng);

  const handleSubmit = async (event) => {
    event.preventDefault();

    handleValidation();

    const envConfig = ARFConfig.helpTopicConfig.find(
      (item) => item.environment === ARFEnvironment
    );

    console.log(
      "envConfig.helpTopicIDs",
      envConfig.helpTopicIDs.MediaInquiryHelpTopicID
    );

    const firstName = sanitizeTextInput(participant.firstName);
    const lastName = sanitizeTextInput(participant.lastName);
    const email = sanitizeTextInput(participant.emailAddress);
    const textArea = sanitizeTextInput(participant.textArea);

    const data = {
      name: firstName,
      email: email,
      subject: "Media inquiry",
      topicId: envConfig.helpTopicIDs.MediaInquiryHelpTopicID,
      media_contact_first_name: firstName,
      media_contact_last_name: lastName,
      media_contact_email: email,
      media_contact_message: textArea,
      message: `data:text/html,<table  border=1 cellpadding=4 style=width:99%;border-spacing:0px;background-color:#EAEAEA><tbody><tr><td><table border=0 cellpadding=5 style=width:100%;border-spacing:0px;background-color:#FFFFFF><tbody><tr style=background-color:#333><td colspan=2><span style=font-family:sans-serif;font-size:16px;color:#fff; letter-spacing: 1;>Media Inquiry </strong></span> </td> </tr> <td><span style=font-family:sans-serif;font-size:12px></span> </td> </tr><tr style=background-color:#EAF2FA><td colspan=2><span style=font-family:sans-serif;font-size:12px><strong>First Name</strong></span> </td> </tr><tr style=background-color:#FFFFFF><td style=width:20px>&nbsp;</td> <td><span style=font-family:sans-serif;font-size:12px>${firstName}</span> </td> </tr><tr style=background-color:#EAF2FA><td colspan=2><span style=font-family:sans-serif;font-size:12px><strong>Last Name</strong></span> </td> </tr><tr style=background-color:#FFFFFF><td style=width:20px>&nbsp;</td> <td><span style=font-family:sans-serif;font-size:12px>${lastName}</span> </td> </tr><tr style=background-color:#EAF2FA><td colspan=2><span style=font-family:sans-serif;font-size:12px><strong>Message</strong></span> </td> </tr><tr style=background-color:#FFFFFF><td style=width:20px>&nbsp;</td> <td><span style=font-family:sans-serif;font-size:12px>${textArea}</span> </td> </tr><tr style=background-color:#EAF2FA><td colspan=2><span style=font-family:sans-serif;font-size:12px><strong>Phone Number</strong></span> </td> </tr><tr style=background-color:#FFFFFF><td style=width:20px>&nbsp;</td> <td><span style=font-family:sans-serif;font-size:12px>${
        participant.phone ? participant.phone : "N/A"
      }</span> </td> </tr><tr style=background-color:#EAF2FA><td colspan=2><span style=font-family:sans-serif;font-size:12px><strong>Email</strong></span> </td> </tr><tr style=background-color:#FFFFFF><td style=width:20px>&nbsp;</td> <td><span style=font-family:sans-serif;font-size:12px>${email}</span> </td> </tr></tbody></table></table`,
    };

    if (participant.phone) {
      data["media_contact_phone_number"] = participant.phone;
    } else {
      data["media_contact_phone_number"] = "N/A";
    }

    const apiPayloadData = {
      arfData: data,
      arfEnvironment: ARFEnvironment,
    };
    console.log("apiPayloadData", apiPayloadData);
    const headerObj = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    };
    if (
      participant.firstName &&
      participant.lastName &&
      participant.emailAddress &&
      participant.textArea
    ) {
      let responseData = axios.post(
        `${process.env.REACT_APP_AWS_LAMBDA_URL}/create-osticket-thread`,
        apiPayloadData,
        headerObj
      );
      toast.promise(responseData, {
        pending: t("submit.toastpendingmessage"),
        success: t("submit.toastsuccessmessage"),
        error: t("submit.toasterrormessage"),
      });

      try {
        const mediaResponse = await responseData;
        console.log("mediaResponse", mediaResponse);

        if (selectedLng === "en") {
          window.location.href = `${
            process.env.REACT_APP_THANK_YOU_LIVE_ENGLISH
          }/?firstName=${participant.firstName ?? ""}&email=${
            participant.emailAddress ?? ""
          }`;
        } else if (selectedLng === "fr") {
          window.location.href = `${
            process.env.REACT_APP_THANK_YOU_LIVE_FRENCH
          }/?firstName=${participant.firstName ?? ""}&email=${
            participant.emailAddress ?? ""
          }`;
        }
      } catch (error) {
        console.log("Media_Inquiery_Error", error);
        axios
        .post(`${process.env.REACT_APP_AWS_LAMBDA_URL}/osticket-error-email`, apiPayloadData, headerObj)
        .then((resp) => {
          console.log("catchResponse", resp);
        })
        .catch((error) => {
          console.log("catchResponseError", error);
        });
        handleClearField();
      }
    } else {
      toast.error(t("mediainquiry.errorMessage"));
    }
  };

  const handleValidation = () => {
    if (
      !participant.firstName ||
      !participant.lastName ||
      !participant.phone ||
      !participant.emailAddress ||
      !participant.textArea
    ) {
      setValidated(true);
    } else {
      setValidated(false);
    }
  };

  const handleClearField = () => {
    setParticipant((participant) => ({
      ...participant,
      firstName: "",
      lastName: "",
      phone: "",
      emailAddress: "",
      textArea: "",
    }));
  };

  const handleBack = (e) => {
    props.resetHome();
  };

  function formatPhoneNumber(value) {
    if (!value) return value;

    const phoneNumber = value.replace(/[^\d]/g, "");

    const phoneNumberLength = phoneNumber.length;

    if (phoneNumberLength < 4) return phoneNumber;

    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }

    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  }

  const handleInput = (evt) => {
    const formattedPhoneNumber = formatPhoneNumber(evt.target.value);
    setParticipant({
      ...participant,
      [evt.target.name]: formattedPhoneNumber,
    });
  };

  function handleChange(evt) {
    const value = evt.target.value;
    setParticipant({
      ...participant,
      [evt.target.name]: value,
    });
  }

  return (
    <div>
      <div className="row bp-card-form">
        <Card className="bp-card    ">
          <Card.Img
            variant="top"
            src={Bgimg}
            id="hideimage"
            className="bp-image bp-media-img-position"
          />

          <Card.Body>
            <Card.Title className="bp-media-title">
              {t("mediainquiry.title")}
            </Card.Title>

            <Form
              noValidate
              validated={validated}
              id="bp-apntmnt"
              className="bp-media"
              onSubmit={handleSubmit}
            >
              <div id="bp-appointment-rspnv">
                <div className="row bp-form-margin">
                  <Form.Group className=" col-md-6 col-lg-6 col-xl-6 mb-3">
                    <div className=" bp-label-name">
                      <Form.Label className="bp-label-font">
                        {t("mediainquiry.firstname")}
                      </Form.Label>
                    </div>

                    <Form.Control
                      type="name"
                      className="col-sm-12 bp-form-input"
                      required
                      name="firstName"
                      onChange={handleChange}
                      value={participant.firstName}
                    />
                    <Form.Control.Feedback
                      type="invalid"
                      className="bp-validation-msg"
                    >
                      {t("mediainquiry.pleaseenterpoperfirstname")}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className=" col-md-6 col-lg-6 col-xl-6 mb-3">
                    <div className=" bp-label-name">
                      <Form.Label className="bp-label-font">
                        {t("mediainquiry.lastname")}
                      </Form.Label>
                    </div>

                    <Form.Control
                      type="name"
                      name="lastName"
                      className="col-sm-12 bp-form-input"
                      onChange={handleChange}
                      value={participant.lastName}
                      required
                    />
                    <Form.Control.Feedback
                      type="invalid"
                      className="bp-validation-msg"
                    >
                      {t("mediainquiry.pleaseenterpoperlastname")}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="row bp-form-margin">
                  <Form.Group className=" col-md-6 col-lg-6 col-xl-6 mb-3">
                    <div className=" bp-label-name">
                      <Form.Label className="bp-label-font">
                        {t("mediainquiry.phonenumber")}
                      </Form.Label>
                    </div>

                    <Form.Control
                      onChange={handleInput}
                      value={participant.phone}
                      type="text"
                      id="phone"
                      name="phone"
                      placeholder="( __ ) ( __ ) ( __ )"
                      className="col-sm-12 bp-form-input"
                      required
                    />
                    <Form.Control.Feedback
                      type="invalid"
                      className="bp-validation-msg"
                    >
                      {t("mediainquiry.pleaseenterpoperphonenumber")}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className=" col-md-6 col-lg-6 col-xl-6 mb-3">
                    <div className="bp-label-name">
                      <Form.Label
                        className={
                          selectedLng === "en"
                            ? "bp-label-font"
                            : "bp-input-ask2 "
                        }
                      >
                        {t("mediainquiry.email")}
                      </Form.Label>
                    </div>

                    <Form.Control
                      type="name"
                      className=" col-sm-12 bp-form-input"
                      placeholder="example@domain.com"
                      onChange={handleChange}
                      name="emailAddress"
                      value={participant.emailAddress}
                      required
                    />
                    <div
                      style={{
                        width: "100%",
                        marginTop: "0.25rem",
                        fontSize: ".875em",
                        color: " #dc3545",
                      }}
                    ></div>
                    <Form.Control.Feedback
                      type="invalid"
                      className="bp-validation-msg"
                    >
                      {t("mediainquiry.pleaseenterpoperemail")}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>

                <div id="bp-form-textarea" className="bp-form-comment2">
                  <Form.Group>
                    <div className=" bp-label-message">
                      <Form.Label className="bp-label-font">
                        {t("mediainquiry.message")}
                      </Form.Label>
                    </div>
                    <Form.Control
                      id="bp-form-textarea"
                      className="bp-form-control2"
                      rows="4"
                      type="text"
                      as="textarea"
                      name="textArea"
                      placeholder=""
                      required
                      value={participant.textArea}
                      onChange={handleChange}
                    />
                    <Form.Control.Feedback
                      type="invalid"
                      className="bp-validation-textarea"
                    >
                      {t("mediainquiry.Pleaseenterpropermessage")}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>

              <ToastContainer />

              <div id="bp-mediaid" className="bp-submit-media">
                <Button
                  id="bp-back-btnid"
                  onClick={handleBack}
                  variant="outline"
                  className="bp-back"
                >
                  {t("mediainquiry.back")}
                </Button>

                <button id="bp-nxt-btn" className="bp-submit-btn-med">
                  {t("mediainquiry.submit")}
                </button>
              </div>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
}

export default Media;
