import React,{Suspense} from "react";
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import common_fr from "./config/translations/fr/translation.json";
import common_en from "./config/translations/en/translation.json"; 



const defaultLang = 'en';
const URLParts = window.location.href.replace('http://', '').replace('https://', '').split('/');
const selectedLang = (URLParts[1] && URLParts[1] === 'fr') ? URLParts[1] : defaultLang;  
const target = document.getElementById('erw-root');


i18next.init({
  interpolation: { escapeValue: false }, 
  lng: selectedLang,
  resources: {
      en: {
          common: common_en              
      },
      fr: {
          common: common_fr 
      },
  },
});

if (target) {
  ReactDOM.render(
    <Suspense fallback="loading">
      <I18nextProvider i18n={i18next}>
        <App />
        
      </I18nextProvider>
    </Suspense>,
    
    target
  );
}

reportWebVitals();
