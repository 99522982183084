import React, { useState } from "react";
import "../assets/css/bp-Style.css";
import Bgimg from "../assets/img/formfill.jpg";
import { Card } from "react-bootstrap";
import { useStore } from "../Hooks/Store";
import { useTranslation } from "react-i18next";

function Sample(props) {
  const [form, setForm] = useState("");

  const selectAppointment = useStore((state) => state.selectAppointment);

  const selectedConcerns = useStore((state) => state.selectedConcerns);
  const { setSelectAppointmentContactIndex } = useStore();

  const { t } = useTranslation("common");

  const handleForm = (e) => {
    setForm(e.target.value);
    if (e.target.value !== "default-select" && e.target.value !== "other") {
      setSelectAppointmentContactIndex(parseInt(e.target.value));
    }
  };

  const handleBack = (e) => {
    if (form === "default-select") {
      return props.goToStep(5);
    } else {
      return props.goToStep(5);
    }
  };

  const handleNext = () => {
    if (form === "other") {
      return props.goToStep(7);
    } else if (form !== "default-select") {
      return props.goToStep(8);
    }
  };
  let participants = [];
  if (
    selectAppointment !== undefined &&
    selectAppointment !== null &&
    selectAppointment.length > 0
  ) {
    participants = selectAppointment.map((item, idx) => {
      if (item.firstName.length > 0 && item.lastName.length > 0) {
        return (
          <option value={idx}>{item.firstName + " " + item.lastName}</option>
        );
      }
    });
  
  }

  return (
    <div>
      <div className="row bp-card-form">
        <Card className="bp-card  col-lg-12   ">
          <Card.Img
            variant="top"
            src={Bgimg}
            id="hideimage"
            className="bp-image bp-form-img-position"
          />
          <Card.Body id="bp-formfill-card">
            <div className="bp-formfill-scroll">
              <div className="bp-form-wrapper">
                <Card.Title
                  className="bp-title-form-position"
                  id="bp-title-positionid"
                >
                  {t("formfill.whoisfillingout")}
                </Card.Title>

                <div className="container bp-formselect-item">
                  <select
                    id="bp-select-form"
                    className="bp-custom-select"
                    onChange={handleForm}
                    autosize={true}
                    clearable={false}
                  >
                    <option
                      id="bp-form-select"
                      value={"default-select"}
                      disabled
                      selected
                    >
                      {t("formfill.pleaseselect")}
                    </option>

                    {participants}

                    <option value="other">{t("formfill.someoneelse")}</option>
                  </select>
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
      <div id="bp-formid" className="bp-submit-formfill">
        <button
          id="bp-back-btnid"
          className="bp-back-btn"
          variant="outline"
          onClick={handleBack}
        >
          {t("formfill.back")}
        </button>

        <div id="bp-stepid" className="bp-step">
          <span className="bp-steps">
            <div className="bp-progress-bar">
              {selectedConcerns[0] ? (
                <div className="bp-current-step">{props.currentStep}</div>
              ) : (
                <div className="bp-current-step"> {props.currentStep - 1}</div>
              )}
              of
              {selectedConcerns[0] ? (
                <div className="bp-total-step">{props.totalSteps}</div>
              ) : (
                <div className="bp-current-step">{props.totalSteps - 1}</div>
              )}
            </div>
          </span>
        </div>

        <button
          id="bp-nxt-btn"
          className="bp-submit-btn"
          onClick={handleNext}
          disabled={!form}
          style={form ? { color: "#ffffff" } : { color: "rgb(179 173 173)" }}
        >
          {t("formfill.next")}
        </button>
      </div>
    </div>
  );
}

export default Sample;
