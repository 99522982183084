import React, { useEffect, useState } from "react";
import "../assets/css/bp-Style.css";
import Bgimg from "../assets/img/language.jpg";
import { Card } from "react-bootstrap";
import { useStore } from "../Hooks/Store";
import aRFConfigData from "../config/bp-arf-form-config.json";
import { useTranslation } from "react-i18next";

function Language(props) {
  const selectedLng = useStore((state) => state.selectedLng);
  const languageVal = aRFConfigData.languages.find(
    (item) => item.isDefault === selectedLng
  );
  const [languageValue, setLanguageVal] = useState([]);
  const [language, setLanguage] = useState({
    languages: [languageVal.languageConfig],
    response: [languageVal.languageConfig],
  });

  const { setCurrentLanguage, setLanguageKey } = useStore();
  const selectedConcerns = useStore((state) => state.selectedConcerns);



  const { t } = useTranslation("common");

  const handleChange = (e) => {
    // Destructuring
    const { value, checked } = e.target;
    const { languages } = language;

    console.log(`testing !!${value} is ${checked}`);

    // Case 1 : The user checks the box
    if (checked) {
      setLanguage({
        languages: [...languages, value],
        response: [...languages, value],
      });
    }

    // Case 2 : The user unchecks the box
    else {
      setLanguage({
        languages: languages.filter((e) => e !== value),
        response: languages.filter((e) => e !== value),
      });
    }
  };

  const handleNext = () => {
    if (language.response.length > 0) {
      props.goToStep(5);
    } else {
      props.goToStep(4);
    }

    setCurrentLanguage([...language.response]);
    setLanguageKey([...language.response]);
  };

  useEffect(() => {
    const resp = language.response[0];
    setLanguageVal(resp);
  }, [setLanguageVal, language]);

  
  let common = [];
  if (aRFConfigData.languages.length > 0) {
    common = aRFConfigData.languages.map((item) => {
      return (
        <div className=" bp-input-alignment">
          <div className="bp-language-align">
            <label className="bp-checkbox-label bp-checkbox">
              <input
                className="bp-form-checkbox"
                type="checkbox"
                name="languages"
                defaultValue={item.languageConfig}
                id="flexCheckDefault"
                onChange={handleChange}
                defaultChecked={selectedLng === item.isDefault ? true : false}
              />
              <span className="bp-radio-item">{t(item.languageConfig)}</span>
            </label>
          </div>
        </div>
      );
    });
  }

  return (
    <div>
      <div className="row bp-card-form">
        <Card className="bp-card  col-lg-12">
          <Card.Img
            variant="top"
            src={Bgimg}
            id="hideimage"
            className="bp-image bp-language-img-position"
          />

          <Card.Body id="bp-language-card">
            <div className="bp-language-scroll">
              <div className="bp-language-wrapper container">
                <Card.Title
                  className=" bp-title-position"
                  id="bp-title-positionid"
                >
                  {t("language.possibilelanguage")}
                </Card.Title>
                <div id="bp-title-apnmnt" className="bp-titlesub-item">
                  <span className="bp-title-sub">
                    {t("language.checkallthatapply")}
                  </span>
                </div>
                {common}
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
      <div id="bp-locationid" className="bp-submit-language">
        <button
          id="bp-back-btnid"
          className="bp-back-btn"
          variant="outline"
          onClick={props.previousStep}
        >
          {t("language.back")}
        </button>

        <div id="bp-stepid" className="bp-step">
          <span className="bp-steps">
            <div className="bp-progress-bar">
              {selectedConcerns.length > 0 ? (
                <div className="bp-current-step">{props.currentStep}</div>
              ) : (
                <div className="bp-current-step">{props.currentStep - 1}</div>
              )}
              of
              {selectedConcerns.length > 0 ? (
                <div className="bp-total-step">{props.totalSteps}</div>
              ) : (
                <div className="bp-current-step">{props.totalSteps - 1}</div>
              )}
            </div>
          </span>
        </div>
        <button
          id="bp-nxt-btn"
          className="bp-submit-btn"
          style={languageValue ? { color: "#ffffff" } : { color: "#d3d3d3" }}
          onClick={handleNext}
        >
          {t("language.next")}
        </button>
      </div>
    </div>
  );
}

export default Language;
