import Modal from "react-bootstrap/Modal";
import React, { useState } from "react";
import HowCanweHelp from "./HowCanWeHelp";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
function App() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { t } = useTranslation("common");
  return (
    <div>
      <div>
        <Button
          className="bp-modal-appointment-btn"
          onClick={handleShow}
          size="lg"
        >
          {t("modal.booknow")}
        </Button>
      </div>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton variant="white"></Modal.Header>

        <Modal.Body
         
        >
          <HowCanweHelp />
        </Modal.Body>
      </Modal>
    </div>
  );
}
export default App;
