import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import "../assets/css/bp-Style.css";
import Bgimg from "../assets/img/information.jpg";
import { useStore } from "../Hooks/Store";
import { useTranslation } from "react-i18next";
function Comment(props) {
  const [information, setInformation] = useState({
    firstName: "",
    lastName: "",
    relation: "",
    phoneNumber: "",
    email: "",
    confirmEmail: "",
  });
  const [validated, setValidated] = useState(false);
  const selectedConcerns = useStore((state) => state.selectedConcerns);

  const {
    setFirstName,
    setLastName,
    setCurrentRelationship,
    setMobileNumber,
    setCurrentEmail,
    setCurrentConfirmEmail,
  } = useStore();

  //handleEvents
  const { t } = useTranslation("common");

  

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

  };

  const handleNext = () => {
    setValidated(true);
    console.log("HandleNext")
    if (information.email === information.confirmEmail) {
      if (
        !information.firstName ||
        !information.lastName ||
        !information.relation ||
        !information.phoneNumber ||
        !information.email ||
        !information.confirmEmail
      ) {
        props.goToStep(7);
        setValidated(true);

      } else {
        props.goToStep(8);
        setValidated(true);
        setFirstName(information.firstName);
        setLastName(information.lastName);
        setCurrentRelationship(information.relation);
        setMobileNumber(information.phoneNumber);
        setCurrentEmail(information.email);
        setCurrentConfirmEmail(information.confirmEmail);
        console.log(information.firstName,"FirstName")
        console.log(information.lastName,"LastName")
        console.log(information.relation,"Relation")
        console.log(information.phoneNumber,"phoneNumber")
        console.log(information.email,"email")
      }
    }
  };


  function formatPhoneNumber(value) {
    if (!value) return value;

    const phoneNumber = value.replace(/[^\d]/g, "");

    const phoneNumberLength = phoneNumber.length;

    if (phoneNumberLength < 4) return phoneNumber;

    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }

    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  }

  const handleInput = (evt) => {
    const formattedPhoneNumber = formatPhoneNumber(evt.target.value);
    setInformation({
      ...information,
      [evt.target.name]: formattedPhoneNumber
    });
  };


  const handleOnChange = (evt) => {
    const value = (evt.target.value);
    setInformation({
      ...information,
      [evt.target.name]: value,
    });
  };


  return (
    <div>
      <div className="row bp-card-form">
        <Card className="bp-card  col-lg-12   ">
          <Card.Img
            variant="top"
            src={Bgimg}
            id="hideimage"
            className="bp-image bp-information-img-position"
          />

          <Card.Body>
            <div className="bp-scroll">
              <Card.Title className="bp-information-title">
                {t("information.pleaseprovideyourinfo")}
              </Card.Title>
              <Form
                noValidate
                validated={validated}
                className="bp-form-language"
                onSubmit={handleSubmit}
              >
                <div id="bp-information-rspnv">
                  <div className="row bp-form-margin">
                    <Form.Group className=" col-md-6 col-lg-6 col-xl-6 col-sm-6 ">
                      <div className=" bp-label-name">
                        <Form.Label className="bp-label-font">
                          {t("information.firstname")}
                        </Form.Label>
                      </div>
                      <Form.Control
                        type="text"
                        name="firstName"
                        className="col-sm-12 bp-form-input"
                        value={information.firstName}
                        onChange={handleOnChange}
                        required
                        autoFocus
                      />
                      <Form.Control.Feedback
                        type="invalid"
                        className="bp-validation-msg"
                      >
                        {t("information.pleaseenterpoperfirstname")}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className=" col-md-6 col-lg-6 col-xl-6 col-sm-6 ">
                      <div className=" bp-label-name">
                        <Form.Label className="bp-label-font">
                          {t("information.lastname")}
                        </Form.Label>
                      </div>

                      <Form.Control
                        type="text"
                        name="lastName"
                        className="col-sm-12 bp-form-input"
                        value={information.lastName}
                        onChange={handleOnChange}
                        required
                      />
                      <Form.Control.Feedback
                        type="invalid"
                        className="bp-validation-msg"
                      >
                        {t("information.pleaseenterpoperlastname")}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>

                  <div className="row bp-form-margin">
                    <Form.Group className="col-md-6 col-lg-6 col-xl-6 mb-3 mb-3">
                      <div className="bp-label-name">
                        <Form.Label className="bp-label-font">
                          {t("information.relationship")}
                        </Form.Label>
                      </div>

                      <Form.Control
                        type="text"
                        name="relation"
                        className="col-sm-12 bp-form-input"
                        onChange={handleOnChange}
                        value={information.relation}
                        placeholder="e.g., family, friend, partner"
                        required
                      />
                      <Form.Control.Feedback
                        type="invalid"
                        className="bp-validation-msg"
                      >
                        {t("information.pleaseenterpoperrelationship")}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className=" col-md-6 col-lg-6 col-xl-6 col-sm-6 ">
                      <div className=" bp-label-name">
                        <Form.Label className="bp-label-font">
                          {t("information.phonenumber")}
                        </Form.Label>
                      </div>

                      <Form.Control
                        type="tel"
                        name="phoneNumber"
                        placeholder="( __ ) ( __ ) ( __ )"
                        className="col-sm-12 bp-form-input"
                        value={information.phoneNumber}
                        onChange={handleInput}
                        required
                        
                      />
                      <Form.Control.Feedback
                        type="invalid"
                        className="bp-validation-msg"
                      >
                        {t("information.pleaseenterpoperphonenumber")}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>

                  <div className="row bp-form-margin">
                    <Form.Group className=" col-md-6 col-lg-6 col-xl-6 mb-3">
                      <div className="bp-label-name">
                        <Form.Label className="bp-label-font">
                          {t("information.emailaddress")}
                        </Form.Label>
                      </div>

                      <Form.Control
                        type="email"
                        name="email"
                        className=" col-sm-12 bp-form-input"
                        placeholder={t("information.emailplaceholder")}
                        value={information.email}
                        onChange={handleOnChange}
                        required
                      />
                      <div
                      className="bp-info-div"
                      >
                      </div>
                      <Form.Control.Feedback
                        type="invalid"
                        className="bp-validation-msg"
                      >
                        {t("information.pleaseenterpoperemail")}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className=" col-md-6 col-lg-6 col-xl-6 mb-3">
                      <div className="bp-label-name">
                        <Form.Label className="bp-label-font">
                          {t("information.confirmemailaddress")}
                        </Form.Label>
                      </div>

                      <Form.Control
                        type="email"
                        name="confirmEmail"
                        className=" col-sm-12 bp-form-input"
                        placeholder={t("information.emailplaceholder")}
                        value={information.confirmEmail}
                        onChange={handleOnChange}
                        required
                      />
                      <div
                        style={{
                          width: "100%",
                          marginTop: "0.25rem",
                          fontSize: ".875em",
                          color: " #dc3545",
                        }}
                      >
                      </div>
                      <Form.Control.Feedback
                        type="invalid"
                        className="bp-validation-msg"
                      >
                        {t("information.pleaseenterpoperemail")}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>
              </Form>
            </div>
          </Card.Body>
        </Card>
      </div>
      <div id="bp-informid" className="bp-submit-information">
        <button
          id="bp-back-btnid"
          className="bp-back-btn"
          variant="outline"
          onClick={() => props.goToStep(6)}
        >
          {t("information.back")}
        </button>

        <div id="bp-stepid" className="bp-step">
          <span className="bp-steps">
            <div className="bp-progress-bar">
              {selectedConcerns[0] ? (
                <div className="bp-current-step">{props.currentStep}</div>
              ) : (
                <div className="bp-current-step">{props.currentStep - 1}</div>
              )}
              of
              {selectedConcerns[0] ? (
                <div className="bp-total-step">{props.totalSteps}</div>
              ) : (
                <div className="bp-current-step">{props.totalSteps - 1}</div>
              )}
            </div>
          </span>
        </div>
        <button
          id="bp-nxt-btn"
          className="bp-submit-btn"
          style={
            information.firstName ||
            information.lastName ||
            information.relation ||
            information.phoneNumber ||
            information.emailAddress ||
            information.confirmEmailAddress
              ? { color: "#ffffff" }
              : { color: "rgb(179 173 173)" }
          }
          onClick={handleNext}
        >
          {t("information.next")}
        </button>
      </div>
    </div>
  );
}

export default Comment;
