import React, { useState } from "react";
import "../assets/css/bp-Style.css";
import Bgimg from "../assets/img/comment.jpg";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { useStore } from "../Hooks/Store";
import { useTranslation } from "react-i18next";

function Comment(props) {
  const [comment, setComment] = useState("");

  const { t } = useTranslation("common");
  const { setAdditionalComment } = useStore();
  const selectedConcerns = useStore((state) => state.selectedConcerns);

  //handleEvents
  const handleChange = (e) => {
    setComment(e.target.value);
  };


  const handleNext = () => {
    setAdditionalComment(comment);
    return props.goToStep(9);
  };

  return (
    <div>
      <div className="row bp-card-form">
        <Card className="bp-card  col-lg-12  ">
          <Card.Img
            variant="top"
            src={Bgimg}
            id="hideimage"
            className="bp-image bp-form-img-position"
          />
          <Card.Body id="bp-comment-card">
            <div className="bp-comment-scroll">
              <div className="bp-comment-wrapper">
                <Card.Title className="bp-additional-title">
                  {t("additionalComment.additionalcomment")}
                </Card.Title>
                <Form.Group className="container bp-form-grp">
                  <Form.Control
                    id="bp-textarea"
                    className="bp-message-additional"
                    as="textarea"
                    rows="4"
                    type="name"
                    placeholder={t("additionalComment.placeholder")}
                    onChange={handleChange}
                    value={comment}
                  />
                </Form.Group>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
      <div id="bp-commentid" className="bp-submit-comment">
        <button
          id="bp-back-btnid"
          className="bp-back-btn"
          variant="outline"
          onClick={() => props.goToStep(6)}
        >
          {t("additionalComment.back")}
        </button>

        <div id="bp-stepid" className="bp-step">
          <span className="bp-steps">
            <div className="bp-progress-bar">
              {selectedConcerns[0] ? (
                <div className="bp-current-step">{props.currentStep}</div>
              ) : (
                <div className="bp-current-step">{props.currentStep - 1}</div>
              )}
              of
              {selectedConcerns[0] ? (
                <div className="bp-total-step">{props.totalSteps}</div>
              ) : (
                <div className="bp-current-step">{props.totalSteps - 1}</div>
              )}
            </div>
          </span>
        </div>
        <button
          id="bp-nxt-btn"
          className="bp-submit-btn"
          onClick={handleNext}
        >
          {t("additionalComment.next")}
        </button>
      </div>
    </div>
  );
}

export default Comment;
