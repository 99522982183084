import React, { useState } from "react";
import { Form, Card } from "react-bootstrap";
import "../assets/css/bp-Style.css";
import { useStore } from "../Hooks/Store";
import Bgimg from "../assets/img/appointment.jpg";
import { useTranslation } from "react-i18next";

const Appointment = (props) => {
  const [validated, setValidated] = useState(false);
  const [participant, setParticipant] = useState({
    firstName: "",
    lastName: "",
    dob: "",
    mobile: "",
    emailAddress: "",
    confirmEmailAddress: "",
  });

  const selectedConcerns = useStore((state) => state.selectedConcerns);
  const selectAppointment = useStore((state) => state.selectAppointment);
  const participantIndex = useStore((state) => state.participantIndex);

  const { t } = useTranslation("common");

  const { setSelectAppointment, setParticipantIndex } = useStore();

  // const handle events
  const validationMsg = t("appointment.pleaseenterpoperemail");

  // Form Onsubmit
  const handleSubmit = (event) => {
    event.preventDefault();

    console.log("Thanks for submitting the form!");
  };

  const handleValidation = () => {
    if (
      !participant.firstName ||
      !participant.lastName ||
      !participant.dob ||
      !participant.emailAddress ||
      !participant.mobile ||
      !participant.confirmEmailAddress
      
    ) {
      setValidated(true);
    } else {
      setValidated(false);
    }
  };
  // Add participant
  const handleAdd = (e) => {
    handleValidation();

    if (
      participant.emailAddress === participant.confirmEmailAddress &&
      participant.firstName &&
      participant.lastName &&
      participant.dob &&
      participant.emailAddress &&
      participant.mobile &&
      participant.confirmEmailAddress
    ) {
      const currentParticipants = selectAppointment;
      const updatedParticipantIndex = participantIndex + 1;
      currentParticipants[participantIndex] = participant;
      setSelectAppointment(currentParticipants);
      setParticipantIndex(updatedParticipantIndex);

      if (participant.emailAddress === participant.confirmEmailAddress) {
        resetHandle();
      }
      props.resetParticipant();
    }
  };

  function formatPhoneNumber(value) {
    if (!value) return value;

    const phoneNumber = value.replace(/[^\d]/g, "");

    const phoneNumberLength = phoneNumber.length;

    if (phoneNumberLength < 4) return phoneNumber;

    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }

    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  }

  const handleInput = (evt) => {
    const formattedPhoneNumber = formatPhoneNumber(evt.target.value);
    setParticipant({
      ...participant,
      [evt.target.name]: formattedPhoneNumber,
    });
  };

  function handleChange(evt) {
    const value = (evt.target.value);

    setParticipant({
      ...participant,
      [evt.target.name]: value,
    });
  }

  // reset Value from Input
  const resetHandle = () => {
    if (participant.emailAddress === participant.confirmEmailAddress) {
      setParticipant((participant) => ({
        ...participant,
        firstName: "",
        lastName: "",
        mobile: "",
        dob: "",
        emailAddress: "",
        confirmEmailAddress: "",
      }));
    }
  };

  const handleNext = () => {
    if (selectAppointment.length > 0) {
      props.goToStep(3);
    } else {
      props.goToStep(3);
    }
  };
  const handleCancel = (e) => {
    props.resetParticipant();
  };
  return (
    <div className="bp-appointment-form-row">
      <div className="row bp-card-form">
        <Card className="bp-card  col-lg-12  ">
          <Card.Img
            variant="top"
            src={Bgimg}
            id="hideimage"
            className="bp-image bp-appointment-img-position"
          />
          <Card.Body id="bp-participant-center">
            <div className="bp-participant-scroll">
              <div className="bp-addParticipant-wrapper">
                <Card.Title className="bp-appointment-title2">
                  {t("appointment.whoisthisappointmentfor")}
                </Card.Title>
                <div id="bp-title-apnmnt" className="bp-appointment-subtitle">
                  <span className="bp-title-sub">
                    {t("appointment.listallparticipant")}
                  </span>
                </div>
                <Form
                  noValidate
                  validated={validated}
                  id="bp-apntmnt"
                  className="bp-form-apmnt"
                  onSubmit={handleSubmit}
                >
                  <div
                    id="bp-appointment-rspnv"
                    className="bp-appointment-container bp-appointment-border"
                  >
                    <div className="row bp-form-margin">
                      <Form.Group className=" col-md-6 col-lg-6 col-xl-6 col-sm-6 ">
                        <div className=" bp-label-name">
                          <Form.Label className="bp-label-font">
                            {t("appointment.firstname")}
                          </Form.Label>
                        </div>
                        <Form.Control
                          type="text"
                          name="firstName"
                          className="col-sm-12 bp-form-input"
                          value={participant.firstName}
                          onChange={handleChange}
                          required
                          autoFocus
                        />
                        <Form.Control.Feedback
                          type="invalid"
                          className="bp-validation-msg"
                        >
                          {t("appointment.pleaseenterpoperfirstname")}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group className=" col-md-6 col-lg-6 col-xl-6 col-sm-6 ">
                        <div className=" bp-label-name">
                          <Form.Label className="bp-label-font">
                            {t("appointment.lastname")}
                          </Form.Label>
                        </div>

                        <Form.Control
                          type="text"
                          name="lastName"
                          className="col-sm-12 bp-form-input"
                          value={participant.lastName}
                          onChange={handleChange}
                          required
                        />
                        <Form.Control.Feedback
                          type="invalid"
                          className="bp-validation-msg"
                        >
                          {t("appointment.pleaseenterpoperlastname")}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                    <div className="row bp-form-margin">
                      <Form.Group className=" col-md-6 col-lg-6 col-xl-6 col-sm-6 ">
                        <div className="bp-label-name">
                          <Form.Label className="bp-label-font">
                            {t("appointment.dateofbirth")}
                          </Form.Label>
                        </div>

                        <Form.Control
                          format="dd-MM-YYYY"
                          id="depositedAmount"
                          max="9999-12-31"
                          value={participant.dob}
                          onChange={handleChange}
                          type="date"
                          name="dob"
                          placeholder="Date of Birth"
                          className="col-sm-12 bp-form-input"
                          required
                          error={false}
                        />

                        <i className="bi bi-calendar"></i>

                        <Form.Control.Feedback
                          type="invalid"
                          className="bp-validation-msg"
                        >
                          {t("appointment.pleaseenterpoperdate")}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group className=" col-md-6 col-lg-6 col-xl-6 col-sm-6 ">
                        <div className=" bp-label-name">
                          <Form.Label className="bp-label-font">
                            {t("appointment.phonenumber")}
                          </Form.Label>
                        </div>

                        <Form.Control
                          value={participant.mobile}
                          onChange={handleInput}
                          type="tel"
                          id="phone"
                          name="mobile"
                          placeholder="( _ ) ( _ ) ( __ )"
                          className="col-sm-12 bp-form-input"
                          required
                        />

                        <Form.Control.Feedback
                          type="invalid"
                          className="bp-validation-msg"
                        >
                          {t("appointment.pleaseenterpoperphonenumber")}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                    <div className="row bp-form-margin">
                      <Form.Group className=" col-md-6 col-lg-6 col-xl-6 col-sm-6 ">
                        <div className="bp-label-name">
                          <Form.Label className="bp-label-font">
                            {t("appointment.emailaddress")}
                          </Form.Label>
                        </div>

                        <Form.Control
                          type="email"
                          value={participant.emailAddress}
                          onChange={handleChange}
                          name="emailAddress"
                          className=" col-sm-12 bp-form-input"
                          placeholder={t("appointment.emailplaceholder")}
                          aria-describedby="inputGroupPrepend"
                          required
                        />
                        <div className="bp-email"></div>
                        <Form.Control.Feedback
                          type="invalid"
                          className="bp-validation-msg"
                        >
                          {t("appointment.pleaseenterpoperemail")}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group className=" col-md-6 col-lg-6 col-xl-6 col-sm-6 ">
                        <div className="bp-label-name">
                          <Form.Label className="bp-label-font">
                            {t("appointment.confirmemail")}
                          </Form.Label>
                        </div>

                        <Form.Control
                          type="email"
                          value={participant.confirmEmailAddress}
                          onChange={handleChange}
                          name="confirmEmailAddress"
                          className="col-sm-12 bp-form-input"
                          placeholder={t("appointment.emailplaceholder")}
                          aria-describedby="inputGroupPrepend"
                          required
                        />
                        <div className="bp-email"></div>
                        <Form.Control.Feedback
                          type="invalid"
                          className="bp-validation-msg"
                        >
                          {validationMsg}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>

                    <div className="bp-remove-btn-container">
                      <button className="bp-cancel" onClick={handleCancel}>
                        {t("appointment.cancel")}
                      </button>
                      <button
                        className="bp-participant"
                        onClick={() => {
                          handleAdd();
                        }}
                      >
                        {t("appointment.addmoreparticpant")}
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </Card.Body>
        </Card>
        <div className="bp-submit-appointment">
          <div className="bp-back-btn-remove"></div>

          <div id="bp-stepid" className="bp-step">
            <span className="bp-steps">
              <div className="bp-progress-bar">
                {selectedConcerns[0] ? (
                  <div className="bp-current-step">3</div>
                ) : (
                  <div className="bp-current-step">2</div>
                )}
                of
                {selectedConcerns[0] ? (
                  <div className="bp-total-step">10</div>
                ) : (
                  <div className="bp-current-step">9</div>
                )}
              </div>
            </span>
          </div>
          <button
            id="bp-nxt-btn"
            className="bp-submit-btn"
            style={{ color: "rgb(179 173 173)" }}
            onClick={() => {
              handleNext();
            }}
            disabled
          >
            {t("appointment.next")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Appointment;
