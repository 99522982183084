import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import "../assets/css/bp-Style.css";
import Bgimg from "../assets/img/summary.jpg";
import { FaPencilAlt } from "react-icons/fa";
import { useStore } from "../Hooks/Store";
import { useTranslation } from "react-i18next";



function Summary(props) {
  const [appointmentvalFirst, setAppointmentvalFirst] = useState([]);

  const selectAppointment = useStore((state) => state.selectAppointment);
  const selectAppointmentContactIndex = useStore(
    (state) => state.selectAppointmentContactIndex
  );

  const currentService = useStore((state) => state.currentService);
  const currentLocation = useStore((state) => state.currentLocation);
  const currentLanguage = useStore((state) => state.currentLanguage);
  const firstName = useStore((state) => state.firstName);
  const lastName = useStore((state) => state.lastName);
  const relationship = useStore((state) => state.relationship);
  const mobileNumber = useStore((state) => state.mobileNumber);
  const email = useStore((state) => state.email);
  const selectedConcerns = useStore((state) => state.selectedConcerns);

  const addiotionalComment = useStore((state) => state.addiotionalComment);
  const currentTextArea = useStore((state) => state.currentTextArea);

  useEffect(() => {
    setAppointmentvalFirst(selectAppointment[0]);
  }, [selectAppointment, appointmentvalFirst]);

  const { t } = useTranslation("common");

  const handleNext = () => {
    props.goToStep(10);
  };

  const handleService = () => {
    props.goToStep(1);
  };
  const handleConcern = () => {
    props.goToStep(2);
  };
  const handleParticipants = () => {
    props.goToStep(3);
  };

  const handleLanguage = () => {
    props.goToStep(4);
  };
  const handleLocation = () => {
    props.goToStep(5);
  };
  const handleInformation = () => {
    props.goToStep(7);
  };
  const handleOtherComments = () => {
    props.goToStep(8);
  };

  let participants = [];
  if (
    selectAppointment !== undefined &&
    selectAppointment !== null &&
    selectAppointment.length > 0
  ) {
    participants = selectAppointment.map((item) => {
      if (item.firstName.length > 0) {
        return (
          <span className="mt-2">
            {item.firstName} <br />
            {item.lastName} <br />
            {item.dob} <br />
            {item.mobile} <br />
            {item.emailAddress} <br />
          </span>
        );
      }
    });
  }

  let information = [];
  if (
    selectAppointment !== undefined &&
    selectAppointment !== null &&
    selectAppointment.length > 0
  ) {
    information = selectAppointment.map((item) => {
      if (item.firstName.length > 0) {
        return (
          <div>
            <span>
              {item.firstName}
              {item.lastName} <br />
              {item.dob} <br />
              {item.mobile} <br />
              {item.emailAddress} <br />
            </span>
          </div>
        );
      }
    });
  }

  let concerns = [];
  concerns = selectedConcerns.map((item) => {
    return <span>{t(item)}</span>;
  });



  return (
    <div>
      <div className="row bp-card-form">
        <Card className="bp-card  col-lg-12  ">
          <Card.Img
            variant="top"
            src={Bgimg}
            className="bp-image bp-summary-img-position"
            id="hideimage"
          />

          <Card.Body>
            <div className="bp-scroll">
              <Card.Title className="bp-summaty-title">
                {t("summary.summary")}
              </Card.Title>
              <div className="bp-summary-titlesub-item container">
                <span className="bp-title-sub">
                  {t("summary.pleaseconfirm")}
                </span>
              </div>

              <div className=" bp-table-box" id="bp-tableid">
                <div className="table-row table-head">
                  <div className="table-cell first-cell">
                    <span className="bp-th bp-vertical-position">
                      {t("summary.service")}:
                    </span>
                  </div>
                  <div className="bp-current-summary">
                    <span className="bp-summary-type">{currentService}</span>
                  </div>
                  <div className="table-cell last-cell">
                    <FaPencilAlt className="bp-pen" onClick={handleService} />
                  </div>
                </div>

                {selectedConcerns[0] || currentTextArea ? (
                  <div className="table-row">
                    <div className="table-cell first-cell">
                      <span className="bp-th">{t("summary.concerns")}:</span>
                    </div>
                    <div className="bp-current-summary">
                      <span className="bp-summary-type">
                        {concerns} {""} {currentTextArea}
                      </span>
                    </div>
                    <div className="table-cell last-cell">
                      <FaPencilAlt className="bp-pen" onClick={handleConcern} />
                    </div>
                  </div>
                ) : null}

                <div className="table-row">
                  <div className="table-cell first-cell">
                    <span className="bp-th">{t("summary.participant")}:</span>
                  </div>
                  <div className="bp-current-summary">{participants}</div>
                  <div className="table-cell last-cell">
                    <FaPencilAlt
                      className="bp-pen"
                      onClick={handleParticipants}
                    />
                  </div>
                </div>

                <hr />
                <div className="table-row">
                  <div className="table-cell first-cell">
                    <span className="bp-th">{t("summary.location")}:</span>
                  </div>
                  <div className="bp-current-summary">
                    <span className="bp-summary-type">
   
                      {currentLocation.map((item) => {
                        return <div>{t(item)}</div>;
                      })}
                    </span>
                  </div>
                  <div className="table-cell last-cell">
                    <FaPencilAlt className="bp-pen" onClick={handleLocation} />
                  </div>
                </div>
                <hr />
                <div className="table-row">
                  <div className="table-cell first-cell">
                    <span className="bp-th">{t("summary.language")}:</span>
                  </div>
                  <div className="bp-current-summary">
                    <span className="bp-summary-type">
                    {currentLanguage.map((item) => {
                        return <div>{t(item)}</div>;
                      })}

                    </span>
                  </div>
                  <div className="table-cell ">
                    <FaPencilAlt className="bp-pen" onClick={handleLanguage} />
                  </div>
                </div>
                <hr />
                <div className="table-row">
                  <div className="table-cell first-cell">
                    <span className="bp-th">
                      {t("summary.yourinformation")}:
                    </span>
                  </div>
                  <div className="bp-current-summary">
                    {firstName ? (
                      <span className="bp-summary-type">
                        {firstName}
                        <br />
                        {lastName}
                        <br />
                        {relationship}
                        <br />
                        {mobileNumber}
                        <br />
                        {email}
                      </span>
                    ) : !firstName ? (
                      <span className="bp-summary-type">
                        {" "}
                        {information[selectAppointmentContactIndex]}
                      </span>
                    ) : null}
                  </div>
                  <div className="table-cell ">
                    <FaPencilAlt
                      className="bp-pen"
                      onClick={handleInformation}
                    />
                  </div>
                </div>
                <hr />
                <div className="table-row">
                  <div className="table-cell first-cell">
                    <span className="bp-th bp-pb-potion">
                      {t("summary.othercomment")}:
                    </span>
                  </div>
                  <div className="bp-current-summary">
                    <span className="bp-summary-type">
                      {addiotionalComment}
                    </span>
                  </div>
                  <div className="table-cell ">
                    <FaPencilAlt
                      className="bp-pen"
                      onClick={handleOtherComments}
                    />
                  </div>
                </div>
              </div>
              <div className="bp-button-bottom"></div>
            </div>
          </Card.Body>
        </Card>
      </div>
      <div className="bp-submit-summary" id="bp-bottom">
        <button
          id="bp-back-btnid"
          className="bp-back-btn"
          variant="outline"
          onClick={props.previousStep}
        >
          {t("summary.back")}
        </button>

        <div id="bp-stepid" className="bp-step">
          <span className="bp-steps">
            <div className="bp-progress-bar">
              {selectedConcerns[0] ? (
                <div className="bp-current-step">{props.currentStep}</div>
              ) : (
                <div className="bp-current-step">{props.currentStep - 1}</div>
              )}
              of
              {selectedConcerns[0] ? (
                <div className="bp-total-step">{props.totalSteps}</div>
              ) : (
                <div className="bp-current-step">{props.totalSteps - 1}</div>
              )}
            </div>
          </span>
        </div>
        <button
          id="bp-nxt-btn"
          className="bp-submit-btn"
          onClick={(e) => {
            handleNext();
          }}
        >
          {t("summary.next")}
        </button>
      </div>
    </div>
  );
}

export default Summary;
