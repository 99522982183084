export const sanitizeTextInput = (strVal) => {
  const arrSpecialChars = ["@", "=", "+", "-"];

  if(strVal !== undefined){

    while (arrSpecialChars.includes(strVal.charAt(0))) {
      strVal = strVal.substring(1);
    }
  
    strVal = strVal.replace(/([@=+\-])+/g, '$1');
    strVal = strVal.replace(/[^A-Za-zÀ-ÿ0-9@.,"' !&$?/:()=+\-_&]+/g, '');
  
    return strVal.replace(/[\u00A0-\u9999<>\&]/gim, (i) => {
      if (i === '&') {
        return '&';
      }
      return "&#" + i.charCodeAt(0) + ";";
    });
  }
};