import React, { useState } from "react";
import "../assets/css/bp-Style.css";
import Bgimg from "../assets/img/concern.jpg";
import { Card, Form } from "react-bootstrap";
import Select, { components } from "react-select";
import { useStore } from "../Hooks/Store";
import { useTranslation } from "react-i18next";
import ARFConfigData  from "../config/bp-arf-form-config.json";
import {sanitizeTextInput} from '../utilties/utilities' 
const MultiValue = (props) => (
  <components.MultiValue {...props}>
    {props.data.chipLabel}
  </components.MultiValue>
);


function AppointmentConcerns(props) {
  const [requestConcerns, setRequestConcerns] = useState([]);
  const [textArea, setTextArea] = useState("");
  const { setSelectedConcerns,setConcernKey,setOtherConcern } = useStore();

  const { t } = useTranslation("common");


  const options = ARFConfigData.configConcerns.sort().map(obj => ({label: t(obj), chipLabel: t(obj), value: (obj)}));
  // console.log('Options', options);

  
  const handleSelect = (valueType, actionType) => {
    console.log("valueType", valueType);
    console.log("actionType", actionType);
    let arrConcerns = requestConcerns;
    console.log('actionType.action',actionType.action);
    if (actionType.action === "select-option") {
      arrConcerns.push(actionType.option.value);
    } else if (actionType.action === "remove-value") {
      console.log('remove-value');

      const delIndex = arrConcerns.indexOf(actionType.removedValue.value);
      console.log('delIndex',delIndex);
      arrConcerns.splice(delIndex, 1);
      console.log('arrConcerns',arrConcerns);
    }else if(actionType.action === "clear"){
      console.log('ACTION TYPE CLEARED');
      arrConcerns = []; // Set arrConcerns to an empty array
      console.log('arrConcerns',arrConcerns);
    }

    setRequestConcerns(arrConcerns);
    setSelectedConcerns(arrConcerns);
    console.log("arrConcerns",arrConcerns)
    setConcernKey(arrConcerns);
  };

  const handleNext = () => {
    console.log("textArea",textArea);
    if(requestConcerns.length >0){
      console.log("requestConcerns",requestConcerns);
      console.log("requestConcerns[0]",requestConcerns[0]);
    }
    if (requestConcerns[0] || textArea  ) {
      props.goToStep(3);
    } else {
      props.goToStep(2);
    }
  };

  const handleTextArea = (e) => {
    setTextArea(e.target.value);
    setOtherConcern(sanitizeTextInput(e.target.value));
  };
  return (
    <div>
      <div className="row bp-card-form">
        <Card className="bp-card  col-lg-12   ">
          <Card.Img
            variant="top"
            src={Bgimg}
            id="hideimage"
            className="bp-image bp-concern-img-position"
          />

          <Card.Body id="bp-concern-center">
            <div className="bp-concern-scroll">
              <div
                className="bp-concern-alignment bp-concern-wrapper"
                id="bp-concern-alignemntid"
              >
                <Card.Title
                  className="bp-title-concern"
                  id="bp-title-container"
                >
                  {t("concern.whatconcern")}
                </Card.Title>

                <div className="bp-titlesub-item ">
                  <span className="bp-title-sub">
                    {t("concern.pleaseselectoneormoreconcerns")}
                  </span>
                </div>

                <div className="bp-App-select">
                  <Select
                    cols="59"
                    id="bp-concernselect-btn"
                    onChange={handleSelect}
                    className="bp-please-select"
                    placeholder={t("concern.pleaseselect")}
                    isMulti={true}
                    options={options}
                    components={{ MultiValue }}
                  />
                </div>

                <div className="bp-textarea-conern ">
                  <div className=" bp-label-name">
                    <Form.Label className="bp-label-font1">
                      {t("concern.othercomments")}
                    </Form.Label>
                  </div>
                  <div className="bp-textarea-concern-wrapper">
                    <textarea
                      className="bp-textarea-box-concern"
                      rows="4"
                      type="text"
                      as="textarea"
                      placeholder=""
                      required
                      value={textArea}
                      onChange={handleTextArea}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
      <div id="bp-concernid" className="bp-submit-concern">
        <button
          id="bp-back-btnid"
          className="bp-back-btn"
          variant="outline"
          onClick={props.previousStep}
        >
          {t("concern.back")}
        </button>

        <div id="bp-stepid" className="bp-step">
          <span className="bp-steps">
            <div className="bp-progress-bar">
              <div className="bp-current-step">{props.currentStep}</div> of
              <div className="bp-total-step">{props.totalSteps}</div>
            </div>
          </span>
        </div>
        <button
          id="bp-nxt-btn"
          className="bp-submit-btn"
          style={
            requestConcerns[0] || textArea
              ? { color: "#ffffff" }
              : { color: "rgb(179 173 173)" }
          }
          onClick={handleNext}
        >
          {t("concern.next")}
        </button>
      </div>
    </div>
  );
}

export default AppointmentConcerns;