import React, { useState } from "react";
import { Card } from "react-bootstrap";
import "../assets/css/bp-Style.css";
import Bgimg from "../assets/img/howcanwehelp.jpg";
import AppointmentRequest from "./AppointmentRequest";
import MediaInquiry from "./MediaInquiry";
import AskQuestion from "./AskQuestion";
import FollowUpAppointment from "./Bookafollowupappointment";
import { useTranslation } from "react-i18next";

function HowCanhelp(props) {
  const { t } = useTranslation("common");
  const radioValue = t("home.firstappointment");

  const [option, setOption] = useState(radioValue);
  const [homePageState, setHomePageState] = useState();

  const handleOption = (e) => {
    setOption(e.target.value);
  };

  const handleNext = (e) => {
    setHomePageState(option);
    if (option === "Join our team") {
      return (
        <a
          className="bp-navlink"
          rel="noopener noreferrer"
          href="https://www.blakepsychology.com/careers/"
          target="_blank"
        >
          {t("home.next")}
        </a>
      );
    }
  };

  const resetHome = () => {
    setHomePageState("");
  };

  return (
    <div>
      {homePageState === t("home.firstappointment") ? (
        <AppointmentRequest resetHome={resetHome} />
      ) : homePageState === t("home.mediainquiry") ? (
        <MediaInquiry resetHome={resetHome} />
      ) : homePageState === t("home.askaquestion") ? (
        <AskQuestion resetHome={resetHome} />
      ) : homePageState === t("home.followupappointment") ? (
        <FollowUpAppointment resetHome={resetHome} />
      ) : (
        <div className="row bp-card-form">
          <Card className="bp-card  col-lg-12   ">
            <Card.Img
              variant="top"
              src={Bgimg}
              id="hideimage"
              className="bp-image bp-home-img-position"
            />

            <Card.Body id="bp-help-card">
              <div className="bp-home-scroll">
                <div className="bp-home-wrapper">
                  <Card.Title className="bp-home-title">
                    {t("home.howcanwehelp")}
                  </Card.Title>

                  <div className="container bp-home-container">
                    <div className="bp-input-align">
                      <label className="bp-radio-label bp-option">
                        <input
                          type="radio"
                          name="choose"
                          className="bp-radio-option"
                          checked={option === t("home.firstappointment")}
                          value={t("home.firstappointment")}
                          onChange={handleOption}
                        />

                        <span className="bp-radio-item">
                          {t("home.firstappointment")}
                        </span>
                      </label>
                    </div>

                    <div className="bp-input-align">
                      <label className="bp-radio-label bp-option">
                        <input
                          type="radio"
                          className="bp-radio-option"
                          checked={option === t("home.followupappointment")}
                          value={t("home.followupappointment")}
                          name="choose"
                          onChange={handleOption}
                        />
                        <span className="bp-radio-item">
                          {t("home.followupappointment")}
                        </span>
                      </label>
                    </div>

                    <div className="bp-input-align">
                      <label className="bp-radio-label bp-option">
                        <input
                          type="radio"
                          className="bp-radio-option"
                          checked={option === t("home.askaquestion")}
                          value={t("home.askaquestion")}
                          name="choose"
                          onChange={handleOption}
                        />
                        <span className="bp-radio-item">
                          {t("home.askaquestion")}
                        </span>
                      </label>
                    </div>

                    <div className="bp-input-align">
                      <label className="bp-radio-label bp-option">
                        <input
                          className="bp-radio-option"
                          type="radio"
                          checked={option === t("home.joinourteam")}
                          value={t("home.joinourteam")}
                          name="choose"
                          onChange={handleOption}
                        />
                        <span className="bp-radio-item">
                          {t("home.joinourteam")}
                        </span>
                      </label>
                    </div>


                    <div className="bp-input-align">
                      <label className="bp-radio-label bp-option">
                        <input
                          type="radio"
                          className="bp-radio-option"
                          checked={option === t("home.mediainquiry")}
                          value={t("home.mediainquiry")}
                          name="choose"
                          onChange={handleOption}
                        />
                        <span className="bp-radio-item">
                          {t("home.mediainquiry")}
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

            </Card.Body>
          </Card>
              <div id="bp-helpid" className="bp-home-btn">
                <div className="bp-hwcwhlp">
                  {option === t("home.joinourteam") ? (
                    <a
                      id="bp-nxt-btn"
                      className="bp-career-btn "
                      rel="noopener noreferrer"
                      href={process.env.REACT_APP_CAREER}
                      target="_blank"
                    >
                      {t("home.next")}
                    </a>
                  ) : (
                    <div className="bp-hwcwhlp">
                      <button
                        id="bp-nxt-btn"
                        className="bp-submit-btn"
                        onClick={handleNext}
                      >
                        {t("home.next")}
                      </button>
                    </div>
                  )}
                </div>
              </div>
        </div>
      )}
    </div>
  );
}

export default HowCanhelp;
