import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import "../assets/css/bp-Style.css";
import Button from "react-bootstrap/Button";
import Bgimg from "../assets/img/media.jpg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";


function Media(props) {
  const [participant, setParticipant] = useState([
    {
      firstName: "",
      lastName: "",
      phone: "",
      emailAddress: "",
      textArea: "",
    },
  ]);
  const [validated, setValidated] = useState(false);
  const { t } = useTranslation("common");


  const defaultLang = "en";
  const URLParts = window.location.href
    .replace("http://", "")
    .replace("https://", "")
    .split("/");
  const selectedLang =
    URLParts[1] && URLParts[1] === "fr" ? URLParts[1] : defaultLang;



  const handleSubmit = (event) => {
    event.preventDefault();

    console.log("refresh prevented");
    console.log(participant.firstName);
    console.log(participant.lastName);
    console.log(participant.phone);
    console.log(participant.emailAddress);
    console.log(participant.textArea);
    setValidated(true);

if ( validated === true && selectedLang === "en") {
      window.location.href = process.env.REACT_APP_THANK_YOU_LIVE_ENGLISH;
    } else if (validated === true && selectedLang === "fr") {
      window.location.href = process.env.REACT_APP_THANK_YOU_LIVE_FRENCH;
    }


    
  };

  const handleBack = (e) => {
    props.resetHome();
  };

  function notify() {
    if (
      participant.firstName &&
      participant.lastName &&
      participant.phone &&
      participant.emailAddress &&
      participant.textArea
    ) {
      toast.success(<span>{t("bookafollowupappointment.toastmessage")}</span>);
    }
  }
  function formatPhoneNumber(value) {
    if (!value) return value;

    const phoneNumber = value.replace(/[^\d]/g, "");

    const phoneNumberLength = phoneNumber.length;

    if (phoneNumberLength < 4) return phoneNumber;

    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }

    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  }

  const handleInput = (evt) => {
    const formattedPhoneNumber = formatPhoneNumber(evt.target.value);
    setParticipant({
      ...participant,
      [evt.target.name]: formattedPhoneNumber
    });
  };



  function handleChange(evt) {
    const value = evt.target.value;
    setParticipant({
      ...participant,
      [evt.target.name]: value,
    });
  }

  return (
    <div>
      <div className="row bp-card-form">
        <Card className="bp-card ">
          <Card.Img
            variant="top"
            src={Bgimg}
            id="hideimage"
            className="bp-image bp-media-img-position"
          />

          <Card.Body>
            <Card.Title className="bp-media-title">
              {t("bookafollowupappointment.title")}
            </Card.Title>

            <Form
              noValidate
              validated={validated}
              id="bp-apntmnt"
              className="bp-media"
              onSubmit={handleSubmit}
            >
              <div  id="bp-appointment-rspnv">
                <div className="row bp-form-margin">
                  <Form.Group className=" col-md-6 col-lg-6 col-xl-6 mb-3">
                    <div className=" bp-label-name">
                      <Form.Label className="bp-label-font">
                        {t("bookafollowupappointment.firstname")}
                      </Form.Label>
                    </div>

                    <Form.Control
                      type="name"
                      className="col-sm-12 bp-form-input"
                      required
                      name="firstName"
                      onChange={handleChange}
                      value={participant.firstName}
                      autoFocus
                    />
                    <Form.Control.Feedback
                      type="invalid"
                      className="bp-validation-msg"
                    >
                      {t("bookafollowupappointment.pleaseenterpoperfirstname")}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className=" col-md-6 col-lg-6 col-xl-6 mb-3">
                    <div className=" bp-label-name">
                      <Form.Label className="bp-label-font">
                        {t("bookafollowupappointment.lastname")}
                      </Form.Label>
                    </div>

                    <Form.Control
                      type="name"
                      name="lastName"
                      className="col-sm-12 bp-form-input"
                      onChange={handleChange}
                      value={participant.lastName}
                      required
                    />
                    <Form.Control.Feedback
                      type="invalid"
                      className="bp-validation-msg"
                    >
                      {t("bookafollowupappointment.pleaseenterpoperlastname")}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="row bp-form-margin">
                  <Form.Group className=" col-md-6 col-lg-6 col-xl-6 mb-3">
                    <div className=" bp-label-name">
                      <Form.Label className="bp-label-font">
                        {t("bookafollowupappointment.phonenumber")}
                      </Form.Label>
                    </div>

                    <Form.Control
                      type="tel"
                      id="phone"
                      name="phone"
                      placeholder="( __ ) ( __ ) ( __ )"
                
                      className="col-sm-12 bp-form-input"
                      required
                     
                      onChange={handleInput}
                      value={participant.phone}
                      
                    />
                    <Form.Control.Feedback
                      type="invalid"
                      className="bp-validation-msg"
                    >
                      {t(
                        "bookafollowupappointment.pleaseenterpoperphonenumber"
                      )}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className=" col-md-6 col-lg-6 col-xl-6 mb-3">
                    <div className="bp-label-name">
                      <Form.Label className="bp-label-font">
                        {t("bookafollowupappointment.email")}
                      </Form.Label>
                    </div>

                    <Form.Control
                      type="name"
                      className=" col-sm-12 bp-form-input"
                      placeholder={t(
                        "bookafollowupappointment.emailplcaeholder"
                      )}
                      onChange={handleChange}
                      name="emailAddress"
                      value={participant.emailAddress}
                      required
                    />
                    <div
                      style={{
                        width: "100%",
                        marginTop: "0.25rem",
                        fontSize: ".875em",
                        color: " #dc3545",
                      }}
            
                    >
                    </div>
                    <Form.Control.Feedback
                      type="invalid"
                      className="bp-validation-msg"
                    >
                    
                      {t("bookafollowupappointment.pleaseenterpoperemail")}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>

                <div id="bp-form-textarea" className=" bp-form-comment2">
                  <Form.Group>
                    <div className=" bp-label-message">
                      <Form.Label className="bp-label-font">
                    
                        {t("bookafollowupappointment.message")}
                      </Form.Label>
                    </div>
                    <Form.Control
                      id="bp-form-textarea"
                      className="bp-form-control2"
                      rows="4"
                      type="text"
                      as="textarea"
                      name="textArea"
                      placeholder=""
                      required
                      value={participant.textArea}
                      onChange={handleChange}
                      
                    />
                    <Form.Control.Feedback type="invalid" className="bp-validation-textarea">
 
                      {t("bookafollowupappointment.Pleaseenterpropermessage")}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>

              <ToastContainer />

              
              <div id="bp-mediaid" className="bp-submit-media">
                <Button
                  id="bp-back-btnid"
                  onClick={handleBack}
                  variant="outline"
                  className="bp-back"
                >
                  {t("bookafollowupappointment.back")}
                </Button>

                <button
                  id="bp-nxt-btn"
                  className="bp-submit-btn-med"
                  onClick={() => {
                    notify();
                  }}
                >
                   {t("bookafollowupappointment.submit")}
                </button>
              </div>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
}

export default Media;
