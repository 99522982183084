import "./App.css";
import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "./components/Modal";
import { useStore } from "./Hooks/Store";


const ContactForm = () => {
  const { setARFEnvironment, setSelectedLng, setReferralUrl} = useStore();

  useEffect(() => {
    const URLParts = window.location.href
      .replace("http://", "")
      .replace("https://", "")
      .split(".");
    const environmentFlag = URLParts && !URLParts[0].includes('staging') && !URLParts[0].includes('localhost') ? 'live' : 'staging';
    setARFEnvironment(environmentFlag);
    // setARFEnvironment("live"); 
    console.log("environmentFlag", environmentFlag);

    const referralURL = window.location.href
    setReferralUrl(referralURL);
  }, [setARFEnvironment, setReferralUrl]);



  useEffect(() => {
    const defaultLang = "en";
    const URLParts = window.location.href
      .replace("http://", "")
      .replace("https://", "")
      .split("/");
    const selectedLang =
      URLParts[1] && URLParts[1] === "fr" ? URLParts[1] : defaultLang;
      console.log('selectedLang',selectedLang);
      setSelectedLng(selectedLang)
  },[setSelectedLng]);

  return (
    <div className="Top-container">
      {/* <TypeOfTherapy/> */}

      {/* <HowCanWehelp /> */}
      <Modal />
    </div>
  );
};

export default ContactForm;
