import React, { useState } from "react";
import { Card, Button } from "react-bootstrap";
import "../assets/css/bp-Style.css";
import Bgimg from "../assets/img/appointment.jpg";
import { useStore } from "../Hooks/Store";
import { useTranslation } from "react-i18next";
import AppointmentForm from "./AppointmentForm";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaPencilAlt } from "react-icons/fa";
import ParticipantDetails from "./ParticipantDetails";

const Appointment = (props) => {
  const [input, setInput] = useState([]);
  const [isShow, setIsShow] = useState(true);
  const [show, setShow] = useState(true);
  const [indexValue, setIndexValue] = useState();

  const [selectedParticipantIndex, setSelectedParticipantIndex] = useState(0);
  const selectedConcerns = useStore((state) => state.selectedConcerns);
  const selectAppointment = useStore((state) => state.selectAppointment);
  const currentSubCategory = useStore((state) => state.currentSubCategory);
  const currentTextArea = useStore((state) => state.currentTextArea);

  const { t } = useTranslation("common");

  const { setSelectAppointment } = useStore();

  // Remove function
  const removeList = (index) => {
    console.log("START removeList: index", index);
    const rows = selectAppointment;
    console.log("BEFORE Splice", rows);
    rows.splice(index, 1);
    console.log("AFTER Splice", rows);
    const updatedParticipantIndex = selectedParticipantIndex - 1;
    console.log("updatedParticipantIndex", updatedParticipantIndex);
    setSelectedParticipantIndex(updatedParticipantIndex);
    setInput(rows);
    console.log("rows", rows);
    setSelectAppointment(rows);
    console.log("END removeList");
  };

  // Form Onsubmit

  // Add participant

  const previousPage = (e) => {
    if (
      currentSubCategory === "Thérapie familiale" ||
      currentSubCategory === "Family Therapy"
    ) {
      props.goToStep(1);
    } else {
      props.goToStep(2);
    }
  };
  // next Button function
  const handleNext = () => {
    if (selectAppointment.length > 0 || input[0]) {
      props.goToStep(4);
    } else {
      props.goToStep(3);
    }
  };

  const handleParticipantEdit = () => {
    setShow(!show);
  };
  const handleIndexValue = (index) => {
    setIndexValue(index);
    console.log("INDEXVALUE", indexValue);
  };

  let participants = [];
  if (
    selectAppointment !== undefined &&
    selectAppointment !== null &&
    selectAppointment.length > 0
  ) {
    participants = selectAppointment.map((item, index) => {
      if (item.firstName.length > 0) {
        return (
          <div className="bp-participant-container " key={index}>
            <button className="bp-participant-data">
              <div className="bp-icons-container container bp-table-row">
                <div className="bp-table-cell bp-first-cell">
                  <span className="bp-details">
                    <span className="bp-participants-name">
                      {item.firstName} {item.lastName}
                    </span>{" "}
                    <br />
                    {item.dob} <br />
                    {item.mobile} <br />
                    {item.emailAddress} <br />
                  </span>
                </div>

                <div className="bp-table2-cell bp-last-cell bp-cell">
                  <div style={{ marginTop: "10px" }}>
                    <FaPencilAlt
                      style={{ width: "20px", height: "20px" }}
                      onClick={() => {
                        handleParticipantEdit();
                        handleIndexValue(index);
                      }}
                    />
                  </div>

                  <div className="bp-delete-icon" style={{ marginTop: "10px" }}>
                    <RiDeleteBin6Line
                      style={{ width: "20px", height: "20px" }}
                      onClick={() => {
                        removeList(index);
                      }}
                    />
                  </div>
                </div>
              </div>
            </button>
          </div>
        );
      }
    });
  }

  const handleAddComponent = () => {
    if (selectAppointment.length < 5) {
      setIsShow(!isShow);
    }
  };

  const resetParticipant = () => {
    setIsShow(!isShow);
  };
  const resetDeails = () => {
    setShow(!show);
  };
  return (
    <div>
      {!show ? (
        <ParticipantDetails
          resetDeails={resetDeails}
          indexValue={indexValue}
          participantVal={selectAppointment[indexValue]}
        />
      ) : (
        <div className="row bp-card-form">
          {!isShow ? (
            <AppointmentForm
              resetParticipant={resetParticipant}
              participantIndex={selectedParticipantIndex}
              style={{ paddingLeft: "0", paddingRight: "0" }}
            />
          ) : (
            <Card className="bp-participant-card  col-lg-12  ">
              <Card.Img
                variant="top"
                src={Bgimg}
                id="hideimage"
                className="bp-image bp-appointment-img-position"
              />
              <Card.Body id="bp-participant-center">
                <div className="bp-participant-scroll">
                  <div className="bp-addParticipant-wrapper">
                    <Card.Title className="bp-appointment-title">
                      {t("appointment.whoisthisappointmentfor")}
                    </Card.Title>
                    <div
                      id="bp-title-apnmnt"
                      className="bp-appointment-subtitle"
                    >
                      <span className="bp-title-sub">
                        {t("appointment.listallparticipant")}
                      </span>
                    </div>

                    <div className="container">
                      {participants}
                      <div className="bp-add-participant-container">
                        <Button
                          variant="outline"
                          className="bp-adda-participant"
                          onClick={handleAddComponent}
                        >
                          + Add a participant
                        </Button>
                      </div>
                    </div>
                    <div className="bp-button-bottom"></div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          )}
        </div>
      )}
      <footer
        className="bp-submit-appointment2"
        style={selectAppointment.length > 0 ? { marginTop: "5.6%" } : {}}
      >
        <button
          id="bp-back-btnid"
          className="bp-back-btn"
          variant="outline"
          onClick={previousPage}
        >
          {t("appointment.back")}
        </button>

        <div id="bp-stepid" className="bp-step">
          <span className="bp-steps">
            <div className="bp-progress-bar">
              {selectedConcerns[0] || currentTextArea ? (
                <div className="bp-current-step">{props.currentStep}</div>
              ) : (
                <div className="bp-current-step">{props.currentStep - 1}</div>
              )}
              of
              {selectedConcerns[0] || currentTextArea ? (
                <div className="bp-total-step">{props.totalSteps}</div>
              ) : (
                <div className="bp-current-step">{props.totalSteps - 1}</div>
              )}
            </div>
          </span>
        </div>
        <button
          id="bp-nxt-btn"
          className="bp-submit-btn"
          style={
            selectAppointment.length > 0
              ? { color: "#ffffff" }
              : { color: "rgb(179 173 173)" }
          }
          onClick={() => {
            handleNext();
          }}
        >
          {t("appointment.next")}
        </button>
      </footer>
    </div>
  );
};

export default Appointment;
