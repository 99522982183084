import React, { useState } from "react";
import "../assets/css/bp-Style.css";
import Bgimg from "../assets/img/location.jpeg";
import { Card } from "react-bootstrap";
import { useStore } from "../Hooks/Store";
import ARFConfigData from "../config/bp-arf-form-config.json";
import { useTranslation } from "react-i18next";
import Select, { components } from "react-select";

const MultiValue = (props) => (
  <components.MultiValue {...props}>
    {props.data.chipLabel}
  </components.MultiValue>
);

const defaultLocations = ARFConfigData.locations
  .filter((location) => location.isDefault)
  .map((location) => location.locationConfig);

function Location(props) {
  const initialRequestConcerns = [...defaultLocations];
  const [requestLocations, setRequestLocations] = useState(
    initialRequestConcerns
  );


  const { setCurrentLocation, setLocationKey } = useStore();

  const { t } = useTranslation("common");
  const selectedConcerns = useStore((state) => state.selectedConcerns);

  const options = ARFConfigData.locations.sort().map((obj) => ({
    label:
      obj.locationConfig === "location.online"
        ? `${t(obj.locationConfig)} *`
        : t(obj.locationConfig),
    chipLabel: t(obj.locationConfig),
    value: obj.locationConfig,
  }));

  const handleSelect = (valueType, actionType) => {
    console.log("valueType", valueType);
    let arrLocations = requestLocations;
    if (actionType.action === "select-option") {
      arrLocations.push(actionType.option.value);
    } else if (actionType.action === "remove-value") {
      const delIndex = arrLocations.indexOf(actionType.removedValue.value);
      arrLocations.splice(delIndex, 1);
    } else if (actionType.action === "clear") {
      arrLocations = []; // Set arrLocations to an empty array
    }

    setRequestLocations(arrLocations);
    setCurrentLocation(arrLocations);
    setLocationKey(arrLocations);
  };

  const handleNext = () => {
    if (requestLocations.length > 0) {
      setCurrentLocation(requestLocations);
      setLocationKey(requestLocations);
    }
    if (requestLocations[0]) {
      props.goToStep(6);
    } else {
      props.goToStep(5);
    }
  };

  const areLocationsEqual = requestLocations.some(
    (location) => location === "location.online"
  );

  return (
    <div className="row bp-card-form">
      <Card className="bp-card   ">
        <Card.Img
          variant="top"
          src={Bgimg}
          id="hideimage"
          className="bp-image bp-location-img-position"
        />

        <Card.Body id="bp-language-card">
          <div className="bp-location-scroll">
            <div className="bp-location-wrapper container">
              <Card.Title
                className="bp-title-position"
                id="bp-title-positionid"
              >
                {t("location.title")}
              </Card.Title>
              <div id="bp-title-apnmnt" className="bp-titlesub-item">
                <span className="bp-title-sub">{t("location.subheading")}</span>
              </div>

              <div className=" bp-input-alignment">
                <div className="bp-location-align">
                  <Select
                    cols="59"
                    id="bp-concernselect-btn"
                    onChange={handleSelect}
                    className="bp-please-select"
                    placeholder={t("concern.pleaseselect")}
                    isMulti={true}
                    options={options}
                    components={{ MultiValue }}
                    value={options.filter((option) =>
                      requestLocations.includes(option.value)
                    )}
                  />
                </div>
                {/* ADD HERE */}

                {areLocationsEqual === true ? (
                  <div className="bp-desc-wrapper">
                    <span className="bp-quebec-descr">
                      {t("location.quebecDescription")}
                    </span>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
      <div id="bp-location-id" className="bp-submit-location">
        <button
          id="bp-back-btnid"
          className="bp-back-btn"
          variant="outline"
          onClick={props.previousStep}
        >
          {t("location.back")}
        </button>

        <div id="bp-stepid" className="bp-step">
          <span className="bp-steps">
            <div className="bp-progress-bar">
              {selectedConcerns[0] ? (
                <div className="bp-current-step">{props.currentStep}</div>
              ) : (
                <div className="bp-current-step">{props.currentStep - 1}</div>
              )}
              of
              {selectedConcerns[0] ? (
                <div className="bp-total-step">{props.totalSteps}</div>
              ) : (
                <div className="bp-current-step">{props.totalSteps - 1}</div>
              )}
            </div>
          </span>
        </div>
        <button
          id="bp-nxt-btn"
          className="bp-submit-btn"
          style={
            requestLocations.length > 0
              ? { color: "#ffffff" }
              : { color: "rgb(179 173 173)" }
          }
          onClick={handleNext}
        >
          {t("location.next")}
        </button>
      </div>
    </div>
  );
}

export default Location;
