import create from "zustand";

export const [useStore] = create((set) => ({
  // one
  currentName: "",
  setCurrentName: (currentName) => set({ currentName }),

  currentLastName: "",
  setCurrentLastName: (currentLastName) => set({ currentLastName }),

  currentDate: "",
  setCurrentDate: (currentDate) => set({ currentDate }),

  emailAddress: "",
  setEmail: (emailAddress) => set({ emailAddress }),

  selectConfirmEmail: "",
  setConfirmEmail: (selectConfirmEmail) => set({ selectConfirmEmail }),

  currentMobileNumber: "",
  setCurrentMobileNumber: (currentMobileNumber) => set({ currentMobileNumber }),

  // two
  currentName2: "",
  setCurrentName2: (currentName2) => set({ currentName2 }),

  currentLastName2: "",
  setCurrentLastName2: (currentLastName2) => set({ currentLastName2 }),

  currentDate2: "",
  setCurrentDate2: (currentDate2) => set({ currentDate2 }),

  emailAddress2: "",
  setEmail2: (emailAddress2) => set({ emailAddress2 }),

  selectConfirmEmail2: "",
  setConfirmEmail2: (selectConfirmEmail2) => set({ selectConfirmEmail2 }),

  currentMobileNumber2: "",
  setCurrentMobileNumber2: (currentMobileNumber2) =>
    set({ currentMobileNumber2 }),

  // three
  currentName3: "",
  setCurrentName3: (currentName3) => set({ currentName3 }),

  currentLastName3: "",
  setCurrentLastName3: (currentLastName3) => set({ currentLastName3 }),

  currentDate3: "",
  setCurrentDate3: (currentDate3) => set({ currentDate3 }),

  emailAddress3: "",
  setEmail3: (emailAddress3) => set({ emailAddress3 }),

  selectConfirmEmail3: "",
  setConfirmEmail3: (selectConfirmEmail3) => set({ selectConfirmEmail3 }),

  currentMobileNumber3: "",
  setCurrentMobileNumber3: (currentMobileNumber3) =>
    set({ currentMobileNumber3 }),

  // four
  currentName4: "",
  setCurrentName4: (currentName4) => set({ currentName4 }),

  currentLastName4: "",
  setCurrentLastName4: (currentLastName4) => set({ currentLastName4 }),

  currentDate4: "",
  setCurrentDate4: (currentDate4) => set({ currentDate4 }),

  emailAddress4: "",
  setEmail4: (emailAddress4) => set({ emailAddress4 }),

  selectConfirmEmail4: "",
  setConfirmEmail4: (selectConfirmEmail4) => set({ selectConfirmEmail4 }),

  currentMobileNumber4: "",
  setCurrentMobileNumber4: (currentMobileNumber4) =>
    set({ currentMobileNumber4 }),

  maleorFemale: "",
  setMaleorFemale: (maleorFemale) => set({ maleorFemale }),

  currentLocation: [],
  setCurrentLocation: (currentLocation) => set({ currentLocation }),

  currentLanguage: [],
  setCurrentLanguage: (currentLanguage) => set({ currentLanguage }),

  firstName: "",
  setFirstName: (firstName) => set({ firstName }),

  lastName: "",
  setLastName: (lastName) => set({ lastName }),

  relationship: "",
  setCurrentRelationship: (relationship) => set({ relationship }),

  mobileNumber: "",
  setMobileNumber: (mobileNumber) => set({ mobileNumber }),

  email: "",
  setCurrentEmail: (email) => set({ email }),

  confirmEmail: "",
  setCurrentConfirmEmail: (confirmEmail) => set({ confirmEmail }),

  addiotionalComment: "",
  setAdditionalComment: (addiotionalComment) => set({ addiotionalComment }),

  currentService: "",
  setCurrentService: (currentService) => set({ currentService }),

  currentSubCategory: [],
  setCurrentSubCategory: (currentSubCategory) => set({ currentSubCategory }),

  selectedConcerns: [],
  setSelectedConcerns: (selectedConcerns) => set({ selectedConcerns }),

  concernKey : [],
  setConcernKey : (concernKey) => set({ concernKey }), 

  // participnats:[],
  // setParticipnats:(participnats) =>set({participnats}),

  osTicket: "",
  setosTicket: (osTicket) => set({ osTicket }),

  otherConcern: "",
  setOtherConcern: (otherConcern) => set({ otherConcern }),

  // selectedLanguage : "",
  // setSelectedLanguage:(selectedLanguage) => set({selectedLanguage}),

  // therapy : "",
  // selectTherapy : (therapy) => set({therapy}),

  selectAppointmentContactIndex: 0,
  setSelectAppointmentContactIndex: (selectAppointmentContactIndex) =>
    set({ selectAppointmentContactIndex }),

  selectAppointment: [],
  setSelectAppointment: (selectAppointment) => set({ selectAppointment }),

  participantIndex: 0,
  setParticipantIndex: (participantIndex) => set({ participantIndex }),

  enableAutoFocus: true,
  setEnableAutoFocus: (enableAutoFocus) => set({ enableAutoFocus }),

  ARFEnvironment: 'staging',
  setARFEnvironment: (ARFEnvironment) => set({ ARFEnvironment }),

  locationKey : [],
  setLocationKey : (locationKey) => set({locationKey}),

  languageKey : [],
  setLanguageKey : (languageKey) => set({languageKey}),

  selectedLng :[],
  setSelectedLng:(selectedLng)=> set({selectedLng}),

  referralUrl : "",
  setReferralUrl:(referralUrl)=> set({referralUrl}),
  

}));
