import React from "react";
import StepWizard from "react-step-wizard";
import Appointment from "./AddParticipant";
import Service from "./Service";
import Concern from "./Concern";
import Location from "./Location";
import Language from "./Language";
import FormFilling from "./FormFilling";
import AdditionalComment from "./AdditionalComment";
import Summary from "./Summary";
import Information from "./Information";
import Submit from "./Submit";

const AppointmentRequest = (props) => {

  let noTransitions = {
    enterRight: '',
    enterLeft: '',
    exitRight: '',
    exitLeft: ''
  };


  return (

   
    <>
      <StepWizard transitions={noTransitions}>
        <Service resetHome={() => props.resetHome()} />
        <Concern />
        <Appointment />
        <Language />
        <Location />
        <FormFilling />
        <Information />
        <AdditionalComment /> 
        <Summary />
        <Submit />
      </StepWizard>
    </>
  );
};

export default AppointmentRequest;
