import React, { useState, useEffect } from "react";
import "../assets/css/bp-Style.css";
import Bgimg from "../assets/img/formfill.jpg";
import { Card, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { useStore } from "../Hooks/Store";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import ARFConfig from "../config/bp-arf-form-config.json";
import { sanitizeTextInput } from "../utilties/utilities";

toast.configure();
function Submit(props) {
  // const [concerns, setConcerns] = useState([]);
  const [otherConcernVal, setOtherConcernVal] = useState("");
  // const [error, setError] = useState();
  const [additionalVal, setAdditionalVal] = useState("");
  const [selectObj, setSelectObj] = useState([]);

  const currentSubCategory = useStore((state) => state.currentSubCategory);
  const currentService = useStore((state) => state.currentService);
  const ARFEnvironment = useStore((state) => state.ARFEnvironment);
  const firstName = useStore((state) => state.firstName);
  const lastName = useStore((state) => state.lastName);
  const relationship = useStore((state) => state.relationship);
  const mobileNumber = useStore((state) => state.mobileNumber);
  const email = useStore((state) => state.email);
  const otherConcern = useStore((state) => state.otherConcern);
  const addiotionalComment = useStore((state) => state.addiotionalComment);
  const concernKey = useStore((state) => state.concernKey);
  const languageKey = useStore((state) => state.languageKey);
  const locationKey = useStore((state) => state.locationKey);

  // const selectedConcerns = useStore((state) => state.selectedConcerns);
  const selectAppointment = useStore((state) => state.selectAppointment);
  const referralUrl = useStore((state) => state.referralUrl);
  const { t } = useTranslation("common");

  const defaultLang = "en";
  const URLParts = window.location.href
    .replace("http://", "")
    .replace("https://", "")
    .split("/");
  const selectedLang =
    URLParts[1] && URLParts[1] === "fr" ? URLParts[1] : defaultLang;

  // useEffect(() => {
  //   //concerns
  //   if (selectedConcerns.length > 0) {
  //     setConcerns(selectedConcerns);
  //   } else if (!selectedConcerns.length > 0) {
  //     setConcerns("N/A");
  //   }
  // }, [selectedConcerns]);

  useEffect(() => {
    //other concern
    if (otherConcern) {
      console.log("otherConcern!!!!!", otherConcern);

      setOtherConcernVal(otherConcern);
    } else if (!otherConcern) {
      setOtherConcernVal("N/A");
    }

    //additional comment
    if (addiotionalComment) {
      setAdditionalVal(sanitizeTextInput(addiotionalComment));
    } else {
      setAdditionalVal("N/A");
    }
  }, [addiotionalComment, otherConcern]);

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  let sanitizedAppointments = [];
  if (selectAppointment.length > 0) {
    sanitizedAppointments = selectAppointment
      .map((appointment) => {
        const sanitizedFirstName = sanitizeTextInput(appointment.firstName);
        const sanitizedLastName = sanitizeTextInput(appointment.lastName);
        const sanitizedEmail = sanitizeTextInput(appointment.emailAddress);
        const sanitizedConfirmEmail = sanitizeTextInput(
          appointment.confirmEmailAddress
        );

        return {
          ...appointment,
          firstName: sanitizedFirstName,
          lastName: sanitizedLastName,
          emailAddress: sanitizedEmail,
          confirmEmailAddress: sanitizedConfirmEmail,
        };
      })
      .filter((appointment) => {
        return (
          appointment.firstName &&
          appointment.lastName &&
          appointment.emailAddress &&
          appointment.confirmEmailAddress
        );
      });
  }

  const handlePromise = async () => {
    const envConfig = ARFConfig.helpTopicConfig.find(
      (item) => item.environment === ARFEnvironment
    );
    const data = {
      name: sanitizedAppointments[0].firstName ?? "",
      email: sanitizedAppointments[0].emailAddress ?? "",
      subject: "Request for first appointment",
      topicId: envConfig.helpTopicIDs.ARFHelpTopicID,
      service_type: currentService,
      service_type_detail: currentSubCategory,
      other_concerns: otherConcernVal,
      additional_comments: additionalVal,
      referral_url: referralUrl,
    };

    //location
    if (
      locationKey !== undefined &&
      locationKey !== null &&
      locationKey.length > 0
    ) {
      const locationReplace = locationKey.map((obj) =>
        obj.replace("location.", "")
      );
      console.log("locationReplace!!!", locationReplace);
      data["location"] = locationReplace.join(",");
    }

    //language
    if (
      languageKey !== undefined &&
      languageKey !== null &&
      languageKey.length > 0
    ) {
      const languageReplace = languageKey.map((obj) =>
        obj.replace("language.", "")
      );
      console.log("languageReplace!!!", languageReplace);
      data["language"] = languageReplace.join(",");
    }

    //concern
    if (
      concernKey !== undefined &&
      concernKey !== null &&
      concernKey.length > 0
    ) {
      const conscernReplace = concernKey.map((obj) =>
        obj.replace("concern.", "")
      );
      console.log("conscernReplace!!!", conscernReplace);
      data["concern"] = conscernReplace.join(",");
    } else {
      concernKey[0] = "null";
      data["concern"] = concernKey[0];
    }

    let participants = [];
    if (
      sanitizedAppointments !== undefined &&
      sanitizedAppointments !== null &&
      sanitizedAppointments.length > 0
    ) {
      participants = sanitizedAppointments.map((item, idx) => {
        if (item.firstName.length > 0) {
          return `<table  border=1 cellpadding=4 style=width:99%;border-spacing:0px;background-color:#EAEAEA><tbody><tr><td><table border=0 cellpadding=5 style=width:100%;border-spacing:0px;background-color:#FFFFFF><tbody><tr style=background-color:#333><td colspan=2><span style=font-family:sans-serif;font-size:16px;color:#fff; letter-spacing: 1;>Participant ${
            idx + 1
          }</strong></span> </td> </tr> <td><span style=font-family:sans-serif;font-size:12px></span> </td> </tr><tr style=background-color:#EAF2FA><td colspan=2><span style=font-family:sans-serif;font-size:12px><strong>First name</strong></span> </td> </tr><tr style=background-color:#FFFFFF><td style=width:20px>&nbsp;</td> <td><span style=font-family:sans-serif;font-size:12px>${
            item.firstName
          }</span> </td> </tr><tr style=background-color:#EAF2FA><td colspan=2><span style=font-family:sans-serif;font-size:12px><strong>Last name</strong></span> </td> </tr><tr style=background-color:#FFFFFF><td style=width:20px>&nbsp;</td> <td><span style=font-family:sans-serif;font-size:12px>${
            item.lastName
          }</span> </td> </tr><tr style=background-color:#EAF2FA><td colspan=2><span style=font-family:sans-serif;font-size:12px><strong>Date of birth</strong></span> </td> </tr><tr style=background-color:#FFFFFF><td style=width:20px>&nbsp;</td> <td><span style=font-family:sans-serif;font-size:12px>${
            item.dob
          }</span> </td> </tr><tr style=background-color:#EAF2FA><td colspan=2><span style=font-family:sans-serif;font-size:12px><strong>Phone number</strong></span> </td> </tr><tr style=background-color:#FFFFFF><td style=width:20px>&nbsp;</td> <td><span style=font-family:sans-serif;font-size:12px>${
            item.mobile
          }</span> </td> </tr><tr style=background-color:#EAF2FA><td colspan=2><span style=font-family:sans-serif;font-size:12px><strong>Email</strong></span> </td> </tr><tr style=background-color:#FFFFFF><td style=width:20px>&nbsp;</td> <td><span style=font-family:sans-serif;font-size:12px>${
            item.emailAddress
          }</span> </td> </tr></tbody></table></table`;
        }
      });
      const messageArray = participants;
      const messageList = messageArray.join("");
      setSelectObj(messageList);
      console.log("selectObj", selectObj);
      data["message"] = `data:text/html,${messageList}`;
    }

    sanitizedAppointments.forEach((participantObj, idx) => {
      if (participantObj.firstName.length > 0) {
        if (!firstName) {
          data["appointment_contact_first_name"] = participantObj.firstName;
          data["appointment_contact_last_name"] = participantObj.lastName;
          data["appointment_contact_relationship"] = "Self";
          data["appointment_contact_phone"] = participantObj.mobile;
          data["appointment_contact_email"] = participantObj.emailAddress;
        } else if (firstName) {
          data["appointment_contact_first_name"] = sanitizeTextInput(firstName);
          data["appointment_contact_last_name"] = sanitizeTextInput(lastName);
          data["appointment_contact_relationship"] =
            sanitizeTextInput(relationship);
          data["appointment_contact_phone"] = mobileNumber;
          data["appointment_contact_email"] = sanitizeTextInput(email);
        }
      }
      if (participantObj.firstName.length > 0) {
        data["participant_" + (idx + 1) + "_first_name"] =
          participantObj.firstName;
        data["participant_" + (idx + 1) + "_last_name"] =
          participantObj.lastName;
        data["participant_" + (idx + 1) + "_dob"] = participantObj.dob;
        data["participant_" + (idx + 1) + "_phone"] = participantObj.mobile;
        data["participant_" + (idx + 1) + "_email"] =
          participantObj.emailAddress;
      }
    });

    const apiPayloadData = {
      arfData: data,
      arfEnvironment: ARFEnvironment,
    };
    console.log("apiPayloadData", apiPayloadData);
    const headerObj = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    };

    let submitResponse = axios.post(
      `${process.env.REACT_APP_AWS_LAMBDA_URL}/create-osticket-thread`,
      apiPayloadData,
      headerObj
    );

    toast.promise(submitResponse, {
      pending: t("submit.toastpendingmessage"),
      success: t("submit.toastsuccessmessage"),
      error: t("submit.toasterrormessage"),
    });

    try {
      const arfResponse = await submitResponse;
      console.log("arfResponse", arfResponse);

      if (selectedLang === "en") {
        window.location.href = `${
          process.env.REACT_APP_THANK_YOU_LIVE_ENGLISH
        }/?firstName=${selectAppointment[0].firstName ?? ""}&email=${
          selectAppointment[0].emailAddress ?? ""
        }`;
      } else if (selectedLang === "fr") {
        window.location.href = `${
          process.env.REACT_APP_THANK_YOU_LIVE_FRENCH
        }/?firstName=${selectAppointment[0].firstName ?? ""}&email=${
          selectAppointment[0].emailAddress ?? ""
        }`;
      }
    } catch (error) {
      console.log("Arf_Error", error);
      // console.log("submitResponse---error", submitResponse);
      // console.log("apiPayloadData--error", apiPayloadData);

      axios
        .post(`${process.env.REACT_APP_AWS_LAMBDA_URL}/osticket-error-email`, apiPayloadData, headerObj)
        .then((resp) => {
          console.log("catchResponse", resp);
        })
        .catch((error) => {
          console.log("catchResponseError", error);
        });
    }
  };

  return (
    <div>
      <div className="row bp-card-form">
        <Card className="bp-card  col-lg-12 col-md-4 col-sm-6">
          <Card.Img
            variant="top"
            src={Bgimg}
            id="hideimage"
            className="bp-image bp-submit-img-position"
          />

          <Card.Body>
            <Card.Title className="bp-submit-title">
              <div className="bp-title-algnmnt">
                {t("submit.readytosubmit")}
              </div>
            </Card.Title>
            {/* {participants} */}
            <div className="bp-subtitle-container">
              <span id="bp-signup-word1" className="bp-subtitle-submit">
                <div className="bp-submit-word">{t("submit.subheading1")}</div>
                <div className="bp-submit-word2">{t("submit.subheading2")}</div>
                <div className="bp-submit-word3">{t("submit.subheading3")}</div>
              </span>
            </div>

            <div className="bp-submitbtn-container">
              <button
                className="bp-submitbtn"
                onClick={() => {
                  handlePromise();
                }}
              >
                {t("submit.submit")}
              </button>
            </div>
            <ToastContainer />
            <div id="bp-mediaid" className="bp-submitbtn-back">
              <Button
                id="bp-back-btnid"
                variant="outline"
                className="submit-back-btn "
                onClick={props.previousStep}
              >
                {t("submit.back")}
              </Button>
            </div>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
}

export default Submit;
