import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import "../assets/css/bp-Style.css";
import Bgimg from "../assets/img/askquestion.jpg";
import Button from "react-bootstrap/Button";
import { useStore } from "../Hooks/Store";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ARFConfig from "../config/bp-arf-form-config.json";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { sanitizeTextInput } from "../utilties/utilities";
import Faq from "../assets/img/faq-icon.png";

function AskQuestion(props) {
  const [participant, setParticipant] = useState([
    {
      firstName: "",
      lastName: "",
      phone: "",
      emailAddress: "",
      textArea: "",
    },
  ]);
  const [validation, setValidation] = useState(false);
  const [validated, setValidated] = useState(false);
  const [helpTopic, setHelpTopic] = useState([]);
  const { t } = useTranslation("common");
  const selectedLng = useStore((state) => state.selectedLng);
  const ARFEnvironment = useStore((state) => state.ARFEnvironment);

  const askQuestions = ARFConfig.askQuestions[0];

  // Get the URL based on the selected language
  const url = askQuestions[selectedLng];

  const handleSubmit = async (event) => {
    const selectValidation =
      askQuestion[0].props.children === "" || !helpTopic ? true : false;
    console.log("selectValidation", selectValidation);
    setValidation(selectValidation);

    event.preventDefault();
    console.log("helpTopic", helpTopic);

    handleValidation();

    if (
      participant.firstName &&
      participant.lastName &&
      participant.emailAddress &&
      participant.textArea &&
      askQuestion[0].props.children !== ""
    ) {
      const askQuestion = ARFConfig.helpTopicConfig.find(
        (obj) => obj.environment === ARFEnvironment
      );
      const askQuestionHelpTopic = askQuestion.askAQuestionHelpTopics.find(
        (obj) => t(obj.helpTopic) === helpTopic
      );

      const firstName = sanitizeTextInput(participant.firstName);
      const lastName = sanitizeTextInput(participant.lastName);
      const email = sanitizeTextInput(participant.emailAddress);
      const textArea = sanitizeTextInput(participant.textArea);

      const data = {
        name: firstName,
        email: email,
        subject: "Ask a question",
        topicId: askQuestionHelpTopic.helpTopicID,
        contact_first_name: firstName,
        contact_last_name: lastName,
        contact_email: email,
        contact_message: textArea,
        message: `data:text/html,<table  border=1 cellpadding=4 style=width:99%;border-spacing:0px;background-color:#EAEAEA><tbody><tr><td><table border=0 cellpadding=5 style=width:100%;border-spacing:0px;background-color:#FFFFFF><tbody><tr style=background-color:#333><td colspan=2><span style=font-family:sans-serif;font-size:16px;color:#fff; letter-spacing: 1;>${t(
          askQuestionHelpTopic.helpTopic
        )} </strong></span> </td> </tr> <td><span style=font-family:sans-serif;font-size:12px></span> </td> </tr><tr style=background-color:#EAF2FA><td colspan=2><span style=font-family:sans-serif;font-size:12px><strong>First Name</strong></span> </td> </tr><tr style=background-color:#FFFFFF><td style=width:20px>&nbsp;</td> <td><span style=font-family:sans-serif;font-size:12px>${firstName}</span> </td> </tr><tr style=background-color:#EAF2FA><td colspan=2><span style=font-family:sans-serif;font-size:12px><strong>Last Name</strong></span> </td> </tr><tr style=background-color:#FFFFFF><td style=width:20px>&nbsp;</td> <td><span style=font-family:sans-serif;font-size:12px>${lastName}</span> </td> </tr><tr style=background-color:#EAF2FA><td colspan=2><span style=font-family:sans-serif;font-size:12px><strong>Message</strong></span> </td> </tr><tr style=background-color:#FFFFFF><td style=width:20px>&nbsp;</td> <td><span style=font-family:sans-serif;font-size:12px>${textArea}</span> </td> </tr><tr style=background-color:#EAF2FA><td colspan=2><span style=font-family:sans-serif;font-size:12px><strong>Phone Number</strong></span> </td> </tr><tr style=background-color:#FFFFFF><td style=width:20px>&nbsp;</td> <td><span style=font-family:sans-serif;font-size:12px>${
          participant.phone ? participant.phone : "N/A"
        }</span> </td> </tr><tr style=background-color:#EAF2FA><td colspan=2><span style=font-family:sans-serif;font-size:12px><strong>Email</strong></span> </td> </tr><tr style=background-color:#FFFFFF><td style=width:20px>&nbsp;</td> <td><span style=font-family:sans-serif;font-size:12px>${email}</span> </td> </tr></tbody></table></table`,
      };

      if (participant.phone) {
        data["contact_phone_number"] = participant.phone;
      } else {
        data["contact_phone_number"] = "N/A";
      }

      const apiPayloadData = {
        arfData: data,
        arfEnvironment: ARFEnvironment,
      };
      console.log("apiPayloadData", apiPayloadData);
      const headerObj = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      };

      let responseData = axios.post(
        `${process.env.REACT_APP_AWS_LAMBDA_URL}/create-osticket-thread`,
        apiPayloadData,
        headerObj
      );
      toast.promise(responseData, {
        pending: t("submit.toastpendingmessage"),
        success: t("submit.toastsuccessmessage"),
        error: t("submit.toasterrormessage"),
      });

      try {
        const askQuestionResponse = await responseData;
        console.log("askQuestionResponse", askQuestionResponse);

        if (selectedLng === "en") {
          window.location.href = `${
            process.env.REACT_APP_THANK_YOU_LIVE_ENGLISH
          }/?firstName=${participant.firstName ?? ""}&email=${
            participant.emailAddress ?? ""
          }`;
        } else if (selectedLng === "fr") {
          window.location.href = `${
            process.env.REACT_APP_THANK_YOU_LIVE_FRENCH
          }/?firstName=${participant.firstName ?? ""}&email=${
            participant.emailAddress ?? ""
          }`;
        }
      } catch (error) {
        console.log("Ask_Question_Error", error);
        axios
          .post(
            `${process.env.REACT_APP_AWS_LAMBDA_URL}/osticket-error-email`,
            apiPayloadData,
            headerObj
          )
          .then((resp) => {
            console.log("catchResponse", resp);
          })
          .catch((error) => {
            console.log("catchResponseError", error);
          });
        handleClearField();
      }
    } else {
      toast.error(t("mediainquiry.errorMessage"));
    }
  };

  //handle submit STOP
  const handleValidation = () => {
    if (
      !participant.firstName ||
      !participant.lastName ||
      !participant.phone ||
      !participant.emailAddress ||
      !participant.textArea
    ) {
      setValidated(true);
    } else {
      setValidated(false);
    }
  };

  const handleClearField = () => {
    setParticipant((participant) => ({
      ...participant,
      firstName: "",
      lastName: "",
      phone: "",
      emailAddress: "",
      textArea: "",
    }));
  };

  const handleBack = (e) => {
    props.resetHome();
  };

  const handleHeathCHange = (e) => {
    console.log("ask check", e.target.value);
    setHelpTopic(e.target.value);
  };

  function formatPhoneNumber(value) {
    if (!value) return value;

    const phoneNumber = value.replace(/[^\d]/g, "");

    const phoneNumberLength = phoneNumber.length;

    if (phoneNumberLength < 4) return phoneNumber;

    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }

    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  }

  const handleInput = (evt) => {
    const formattedPhoneNumber = formatPhoneNumber(evt.target.value);
    setParticipant({
      ...participant,
      [evt.target.name]: formattedPhoneNumber,
    });
  };

  function handleChange(evt) {
    const value = evt.target.value;
    setParticipant({
      ...participant,
      [evt.target.name]: value,
    });
  }
  // console.log("helptopic", helpTopic.length);

  let helptopicObj = [];
  let askQuestion = [];
  helptopicObj = ARFConfig.helpTopicConfig.find(
    (obj) => obj.environment === ARFEnvironment
  );
  askQuestion = helptopicObj.askAQuestionHelpTopics.map((obj) => {
    return <option>{t(obj.helpTopic)}</option>;
  });

  // console.log("ARFEnvironment", ARFEnvironment);
  // console.log("props.children", askQuestion[0].props.children);
  if (askQuestion[0].props.children === "" || undefined) {
    console.log("undefined");
  }

  return (
    <div>
      <div className="row bp-card-form">
        <Card className="bp-card  ">
          <Card.Img
            variant="top"
            src={Bgimg}
            id="hideimage"
            className="bp-image bp-ask-img-position"
          />

          <Card.Body>
            <Card.Title className="bp-ask-title">
              {t("askaquestion.title")}
            </Card.Title>
            <div className=" bp-mobile-only pt-4 bp-ask-mobile ">
              <img
                src={Faq}
                alt="faq"
                className={
                  selectedLng === "en" ? "bp-mobilev-img" : "bp-mobilev-img-fr"
                }
              />

              <p className="bp-mobilev-text">
                <span className="bp-all-span">
                  <a href={url} className="bp-faq-color">
                    {t("askaquestion.vistFAQ1")}
                  </a>
                </span>
                <span className="bp-faqMob-span2">
                  {t("askaquestion.vistFAQ2")}
                </span>
              </p>
            </div>

            <Form
              noValidate
              validated={validated}
              id="bp-apntmnt"
              onSubmit={handleSubmit}
              className="bp-question"
            >
              <div id="bp-appointment-rspnv">
                <div className="row bp-form-margin">
                  <Form.Group className=" col-md-6 col-lg-6 col-xl-6 bp-input-ask">
                    <div className=" bp-label-name">
                      <Form.Label className="bp-label-font">
                        {t("askaquestion.firstname")}
                      </Form.Label>
                    </div>

                    <Form.Control
                      type="text"
                      name="firstName"
                      className="col-sm-12 bp-form-input"
                      onChange={handleChange}
                      value={participant.firstName}
                      required
                    />
                    <Form.Control.Feedback
                      type="invalid"
                      className="bp-validation-msg"
                    >
                      {t("askaquestion.pleaseenterpoperfirstname")}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className=" col-md-6 col-lg-6 col-xl-6 bp-input-ask">
                    <div className=" bp-label-name">
                      <Form.Label className="bp-label-font">
                        {t("askaquestion.lastname")}
                      </Form.Label>
                    </div>
                    <Form.Control
                      type="text"
                      name="lastName"
                      className="col-sm-12 bp-form-input"
                      onChange={handleChange}
                      value={participant.lastName}
                      required
                    />
                    <Form.Control.Feedback
                      type="invalid"
                      className="bp-validation-msg"
                    >
                      {t("askaquestion.pleaseenterpoperlastname")}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="row bp-form-margin">
                  <Form.Group className=" col-md-6 col-lg-6 col-xl-6 bp-input-ask">
                    <div
                      className={
                        selectedLng === "en"
                          ? "bp-label-name"
                          : "bp-input-ask2 "
                      }
                    >
                      <Form.Label className="bp-label-font">
                        {t("askaquestion.email")}
                      </Form.Label>
                    </div>

                    <Form.Control
                      type="text"
                      name="emailAddress"
                      className="col-sm-12 bp-form-input"
                      placeholder={t("askaquestion.emailplcaeholder")}
                      onChange={handleChange}
                      value={participant.emailAddress}
                      required
                    />
                    <div
                      style={{
                        width: "100%",
                        marginTop: "0.25rem",
                        fontSize: ".875em",
                        color: " #dc3545",
                      }}
                    ></div>
                    <Form.Control.Feedback
                      type="invalid"
                      className="bp-validation-msg"
                    >
                      {t("askaquestion.pleaseenterpoperemail")}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className=" col-md-6 col-lg-6 col-xl-6 bp-input-ask">
                    <div className=" bp-label-name">
                      <Form.Label className="bp-label-font">
                        {t("askaquestion.phonenumber")}
                      </Form.Label>
                    </div>

                    <Form.Control
                      onChange={handleInput}
                      value={participant.phone}
                      type="tel"
                      id="phone"
                      name="phone"
                      placeholder="( __ ) ( __ ) ( __ )"
                      className="col-sm-12 bp-form-input"
                      required
                    />
                    <Form.Control.Feedback
                      type="invalid"
                      className="bp-validation-msg"
                    >
                      {t("askaquestion.pleaseenterpoperphonenumber")}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>

                <div className="row bp-form-margin">
                  <Form.Group className="col-md-6 col-lg-6 col-xl-6 bp-input-ask">
                    <div className="bp-label-name">
                      <Form.Label className="bp-label-font">
                        {t("askaquestion.helptopics")}
                      </Form.Label>
                    </div>

                    <Form.Select
                      onChange={handleHeathCHange}
                      className={`bp-select-${
                        validation === true ? "validation" : "menu"
                      }`}
                      required
                    >
                      <option disabled selected>
                        {" "}
                        {t("askaquestion.pleaseselect")}
                      </option>
                      {askQuestion}
                    </Form.Select>
                    {validation === true ? (
                      <Form.Label className="bp-validation-lable">
                        {t("askaquestion.pleaseEnterProperDepartment")}
                      </Form.Label>
                    ) : null}
                  </Form.Group>

                  {/* start */}

                  <Form.Group className="col-md-6 col-lg-6 col-xl-6    bp-desktop-only bp-ask-desktop">
                    <img src={Faq} alt="faq" className="bp-webv-img" />
                    <p className="bp-webv-text">
                      <span className="bp-all-span">
                        <a href={url} className="bp-faq-color">
                          {t("askaquestion.vistFAQ1")}
                        </a>
                      </span>
                      <span className="bp-faq-web-span">
                        {t("askaquestion.vistFAQ2")}
                      </span>
                    </p>
                  </Form.Group>
                  {/* END */}
                </div>

                <div id="bp-form-textarea" className="bp-form-comment2">
                  <Form.Group>
                    <div className=" bp-label-message">
                      <Form.Label className="bp-label-font">
                        {t("askaquestion.message")}
                      </Form.Label>
                    </div>
                    <Form.Control
                      id="bp-form-textarea"
                      className="bp-form-control2"
                      name="textArea"
                      rows="4"
                      type="text"
                      as="textarea"
                      placeholder=""
                      required
                      value={participant.textArea}
                      onChange={handleChange}
                    />
                    <Form.Control.Feedback
                      type="invalid"
                      className="bp-validation-textarea"
                    >
                      {t("askaquestion.Pleaseenterpropermessage")}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>

              <ToastContainer />

              <div id="bp-mediaid" className="bp-submit-media">
                <Button
                  id="bp-back-btnid"
                  onClick={handleBack}
                  variant="outline"
                  className="bp-back"
                >
                  {t("askaquestion.back")}
                </Button>

                <button id="bp-nxt-btn" className="bp-submit-btn-med">
                  {t("askaquestion.submit")}
                </button>
              </div>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
}

export default AskQuestion;
